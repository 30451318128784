import { DurationFormat } from "@formatjs/intl-durationformat";
import type { Locale } from "~app/config";

export function formatMinutes(
  value: number | null | undefined,
  locale: Locale,
) {
  if (typeof value !== "number") return null;

  const formatter = new DurationFormat(locale, { style: "long" });

  return formatter.format({ minutes: Math.ceil(value) });
}
