import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const BoundingBoxLines = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A bounding box area with diagonal stripes</title>
        {props.weight === "regular" ? (
          <>
            <path
              d="M26 12C26.5304 12 27.0391 11.7893 27.4142 11.4142C27.7893 11.0391 28 10.5304 28 10V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4H22C21.4696 4 20.9609 4.21071 20.5858 4.58579C20.2107 4.96086 20 5.46957 20 6V7H12V6C12 5.46957 11.7893 4.96086 11.4142 4.58579C11.0391 4.21071 10.5304 4 10 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V10C4 10.5304 4.21071 11.0391 4.58579 11.4142C4.96086 11.7893 5.46957 12 6 12H7V20H6C5.46957 20 4.96086 20.2107 4.58579 20.5858C4.21071 20.9609 4 21.4696 4 22V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H10C10.5304 28 11.0391 27.7893 11.4142 27.4142C11.7893 27.0391 12 26.5304 12 26V25H20V26C20 26.5304 20.2107 27.0391 20.5858 27.4142C20.9609 27.7893 21.4696 28 22 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V22C28 21.4696 27.7893 20.9609 27.4142 20.5858C27.0391 20.2107 26.5304 20 26 20H25V12H26ZM22 6H26V10H22V6ZM6 6H10V7.9875C9.99835 7.99575 9.99835 8.00425 10 8.0125V10H6V6ZM10 26H6V22H10V23.9875C9.99835 23.9958 9.99835 24.0042 10 24.0125V26ZM26 26H22V22H26V26ZM23 20H22C21.4696 20 20.9609 20.2107 20.5858 20.5858C20.2107 20.9609 20 21.4696 20 22V23H12V22C12 21.4696 11.7893 20.9609 11.4142 20.5858C11.0391 20.2107 10.5304 20 10 20H9V12H10C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V9H20V10C20 10.5304 20.2107 11.0391 20.5858 11.4142C20.9609 11.7893 21.4696 12 22 12H23V20Z"
              fill={color}
            />
            <path
              d="M24 18.3432L13.6569 8"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M10.8762 10.8762L21.2242 21.2242"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M8.05072 13.7076L18.2442 23.9011"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        ) : (
          <>
            <path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 10V8L10 8V10H7V22H10V24H22V22H25V10H22Z"
              fill={color}
            />
            <path
              d="M26 12C26.5304 12 27.0391 11.7893 27.4142 11.4142C27.7893 11.0391 28 10.5304 28 10V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4H22C21.4696 4 20.9609 4.21071 20.5858 4.58579C20.2107 4.96086 20 5.46957 20 6V7H12V6C12 5.46957 11.7893 4.96086 11.4142 4.58579C11.0391 4.21071 10.5304 4 10 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V10C4 10.5304 4.21071 11.0391 4.58579 11.4142C4.96086 11.7893 5.46957 12 6 12H7V20H6C5.46957 20 4.96086 20.2107 4.58579 20.5858C4.21071 20.9609 4 21.4696 4 22V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H10C10.5304 28 11.0391 27.7893 11.4142 27.4142C11.7893 27.0391 12 26.5304 12 26V25H20V26C20 26.5304 20.2107 27.0391 20.5858 27.4142C20.9609 27.7893 21.4696 28 22 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V22C28 21.4696 27.7893 20.9609 27.4142 20.5858C27.0391 20.2107 26.5304 20 26 20H25V12H26ZM22 6H26V10H22V6ZM6 6H10V7.9875C9.99835 7.99575 9.99835 8.00425 10 8.0125V10H6V6ZM10 26H6V22H10V23.9875C9.99835 23.9958 9.99835 24.0042 10 24.0125V26ZM26 26H22V22H26V26ZM23 20H22C21.4696 20 20.9609 20.2107 20.5858 20.5858C20.2107 20.9609 20 21.4696 20 22V23H12V22C12 21.4696 11.7893 20.9609 11.4142 20.5858C11.0391 20.2107 10.5304 20 10 20H9V12H10C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V9H20V10C20 10.5304 20.2107 11.0391 20.5858 11.4142C20.9609 11.7893 21.4696 12 22 12H23V20Z"
              fill={color}
            />
            <path
              d="M24 18.3432L13.6569 8"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M10.8762 10.8762L21.2242 21.2242"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M8.05072 13.7076L18.2442 23.9011"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        )}
      </svg>
    );
  },
);
