"use client";

import { useEffect, useMemo, useState } from "react";

export function useScreenSize() {
  const [width, setWidth] = useState<number | null>(null);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  const isMobile = width && width <= 768;
  const isTablet = width && width <= 1024;
  const isDesktop = width && width > 1024;

  return useMemo(
    () => ({ isMobile, isTablet, isDesktop, width }),
    [isMobile, isTablet, isDesktop, width],
  );
}
