"use client";

import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { cn } from "~utils";
import styles from "./radio-button.module.css";

export function RadioButton({
  className,
  ...rest
}: RadixRadioGroup.RadioGroupItemProps) {
  return (
    <RadixRadioGroup.Item className={cn(styles.radio, className)} {...rest}>
      <RadixRadioGroup.Indicator className={styles.indicator} />
    </RadixRadioGroup.Item>
  );
}
