"use client";

import { useLayoutEffect } from "react";
import { ampli } from "~lib/ampli";

export function useSectionTracking() {
  useLayoutEffect(() => {
    const onClick = (event: MouseEvent) => {
      let sectionType: string | null = null;
      let pageType: string | null = null;
      let href: string | null = null;

      let target = event.target as HTMLElement;

      while (target) {
        if (target.hasAttribute("data-section-type")) {
          sectionType = target.getAttribute("data-section-type");
        }

        if (target.hasAttribute("href")) {
          href = target.getAttribute("href");
        }

        if (target.hasAttribute("data-page-type")) {
          pageType = target.getAttribute("data-page-type");
          // No reason to go further up the tree once we are at page type element level
          break;
        }

        target = target.parentElement as HTMLElement;
      }

      if (href !== null && sectionType !== null && pageType !== null) {
        ampli.sectionLinkClicked({
          sectionType,
          pageType,
          sectionLinkHref: href,
        });
        ampli.flush();
      }
    };

    document.addEventListener("click", onClick);

    return () => document.removeEventListener("click", onClick);
  }, []);
}
