import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const Tillage = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>Tillage</title>
        {props.weight === "regular" ? (
          <>
            <path
              d="M24.9244 20.2843C24.3618 21.4562 23.5738 22.5059 22.6055 23.3734C21.6373 24.2408 20.5076 24.9091 19.2811 25.3399C18.0545 25.7708 16.7551 25.9559 15.4571 25.8846C14.159 25.8133 12.8877 25.487 11.7157 24.9244C10.5438 24.3618 9.49407 23.5738 8.62662 22.6055C7.75917 21.6373 7.09093 20.5076 6.66005 19.2811C6.22918 18.0545 6.04411 16.7551 6.11541 15.4571C6.1867 14.159 6.51298 12.8877 7.0756 11.7157C7.63822 10.5438 8.42617 9.49407 9.39445 8.62662C10.3627 7.75917 11.4924 7.09093 12.7189 6.66005C13.9455 6.22918 15.2449 6.04411 16.5429 6.11541C17.841 6.1867 19.1123 6.51298 20.2843 7.0756C21.4563 7.63822 22.5059 8.42617 23.3734 9.39445C24.2408 10.3627 24.9091 11.4924 25.3399 12.7189C25.7708 13.9455 25.9559 15.2449 25.8846 16.5429C25.8133 17.841 25.487 19.1123 24.9244 20.2843L24.9244 20.2843Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.27961 8.73112C7.36663 10.4998 6.22797 12.9512 6.11056 15.5538C5.99314 18.1565 6.90644 20.7005 8.65239 22.6342C10.3983 24.5679 12.8362 25.7354 15.4373 25.8835C18.0383 26.0316 20.5929 25.1483 22.5471 23.4253"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.8269 16.0919C18.8149 16.4632 18.7298 16.8284 18.5766 17.1668C18.4233 17.5051 18.205 17.81 17.9339 18.064C17.6629 18.3179 17.3445 18.516 16.9969 18.6469C16.6493 18.7778 16.2793 18.839 15.9081 18.8269C15.5368 18.8149 15.1716 18.7298 14.8332 18.5766C14.4949 18.4233 14.19 18.205 13.936 17.9339C13.6821 17.6629 13.484 17.3445 13.3531 16.9969C13.2222 16.6493 13.161 16.2793 13.1731 15.9081C13.1851 15.5368 13.2702 15.1716 13.4234 14.8332C13.5767 14.4949 13.795 14.19 14.0661 13.936C14.3371 13.6821 14.6555 13.484 15.0031 13.3531C15.3507 13.2222 15.7207 13.161 16.0919 13.1731C16.4632 13.1851 16.8284 13.2702 17.1668 13.4234C17.5051 13.5767 17.81 13.795 18.064 14.0661C18.3179 14.3371 18.516 14.6555 18.6469 15.0031C18.7778 15.3507 18.839 15.7207 18.8269 16.0919L18.8269 16.0919Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 6L18 3"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M16 29L18 26"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26 15L29 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M3 15L6 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M23.7782 22.364L24.2664 24.805L24.4853 25.8995"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M7.51473 6.10051L8.22183 9.63605"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9.63606 23.7782L6.10053 24.4853"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.8995 7.51473L22.364 8.22183"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        ) : (
          <>
            <path
              opacity="0.2"
              d="M23 23C19.134 26.866 12.866 26.866 9 23C5.13401 19.134 5.13401 12.866 9 9C12.866 5.13401 19.134 5.13401 23 9C26.866 12.866 26.866 19.134 23 23ZM13.5902 13.5902C12.2592 14.9211 12.2592 17.0789 13.5902 18.4098C14.9211 19.7408 17.0789 19.7408 18.4098 18.4098C19.7408 17.0789 19.7408 14.9211 18.4098 13.5902C17.0789 12.2592 14.9211 12.2592 13.5902 13.5902Z"
              fill={color}
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.27961 8.73112C10.2342 7.84859 11.3532 7.16271 12.5728 6.71265C13.7925 6.26259 15.0888 6.05716 16.3878 6.10809C17.6869 6.15902 18.9631 6.46532 20.1438 7.00949C21.3244 7.55366 22.3863 8.32504 23.2689 9.2796C24.1514 10.2342 24.8373 11.3532 25.2873 12.5728C25.7374 13.7925 25.9428 15.0888 25.8919 16.3878C25.841 17.6868 25.5347 18.9631 24.9905 20.1438C24.4463 21.3244 23.6749 22.3863 22.7204 23.2689C21.7658 24.1514 20.6468 24.8373 19.4271 25.2873C18.2075 25.7374 16.9112 25.9428 15.6122 25.8919C14.3131 25.841 13.0369 25.5347 11.8562 24.9905C10.6756 24.4463 9.61366 23.6749 8.73113 22.7204C7.84859 21.7658 7.16272 20.6468 6.71266 19.4271C6.2626 18.2075 6.05717 16.9112 6.1081 15.6122C6.15903 14.3131 6.46533 13.0369 7.00949 11.8562C7.55366 10.6756 8.32505 9.61365 9.27961 8.73112L9.27961 8.73112Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.8269 16.0919C18.8149 16.4632 18.7298 16.8284 18.5766 17.1668C18.4233 17.5051 18.205 17.81 17.9339 18.064C17.6629 18.3179 17.3445 18.516 16.9969 18.6469C16.6493 18.7778 16.2793 18.839 15.9081 18.8269C15.5368 18.8149 15.1716 18.7298 14.8332 18.5766C14.4949 18.4233 14.19 18.205 13.936 17.9339C13.6821 17.6629 13.484 17.3445 13.3531 16.9969C13.2222 16.6493 13.161 16.2793 13.1731 15.9081C13.1851 15.5368 13.2702 15.1716 13.4234 14.8332C13.5767 14.4949 13.795 14.19 14.0661 13.936C14.3371 13.6821 14.6555 13.484 15.0031 13.3531C15.3507 13.2222 15.7207 13.161 16.0919 13.1731C16.4632 13.1851 16.8284 13.2702 17.1668 13.4234C17.5051 13.5767 17.81 13.795 18.064 14.0661C18.3179 14.3371 18.516 14.6555 18.6469 15.0031C18.7778 15.3507 18.839 15.7207 18.8269 16.0919L18.8269 16.0919Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 6L18 3"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M16 29L18 26"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26 15L29 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M3 15L6 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M23.7782 22.364L24.2664 24.805L24.4853 25.8995"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M7.51473 6.10051L8.22183 9.63605"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9.63606 23.7782L6.10053 24.4853"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.8995 7.51473L22.364 8.22183"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        )}
      </svg>
    );
  },
);
