import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const StackLines = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A stack of lined rectangles</title>
        {props.weight === "regular" ? (
          <>
            <g clipPath="url(#clip0_4593_53303)">
              <path
                d="M2 13L16 21L30 13L16 5L2 13Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 18L16 26L30 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 18L25 10.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 15L20 7.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4593_53303">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        ) : (
          <>
            <g clipPath="url(#clip0_4602_53770)">
              <path
                opacity="0.2"
                d="M2 13L16 21L30 13L16 5L2 13Z"
                fill={color}
              />
              <path
                d="M2 13L16 21L30 13L16 5L2 13Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 18L16 26L30 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 18L25 10.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 15L20 7.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4602_53770">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    );
  },
);
