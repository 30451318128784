import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const Crop = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A field of wheat</title>
        {props.weight === "regular" ? (
          <>
            <path
              d="M7.30432 16.3443C7.97193 16.3721 9.30714 16.1444 9.30714 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9.97475 10.6732C9.0846 10.8309 7.30432 11.7377 7.30432 14.1032C7.30432 16.4687 7.30432 19.0313 7.30432 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M6.50906 6.92327C7.36381 7.77802 7.8814 8.74201 8.06313 9.57066C8.24769 10.4122 8.06879 10.9695 7.7717 11.2666C7.47461 11.5637 6.91732 11.7426 6.07574 11.558C5.2471 11.3763 4.28311 10.8587 3.42836 10.004C2.64982 9.22543 2.24096 8.171 2.10759 7.21323C2.04139 6.73782 2.04802 6.31842 2.09535 6.00759C2.12299 5.82605 2.15784 5.72205 2.17715 5.67206C2.22714 5.65275 2.33113 5.6179 2.51268 5.59026C2.82351 5.54293 3.24291 5.5363 3.71832 5.6025C4.67609 5.73587 5.73052 6.14474 6.50906 6.92327Z"
              stroke={color}
              strokeWidth="2"
            />
            <path
              d="M13.3043 16.3443C13.9719 16.3721 15.3071 16.1444 15.3071 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M15.9747 10.6732C15.0846 10.8309 13.3043 11.7377 13.3043 14.1032C13.3043 16.4687 13.3043 19.0313 13.3043 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.3043 16.3443C19.9719 16.3721 21.3071 16.1444 21.3071 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M21.9747 10.6732C21.0846 10.8309 19.3043 11.7377 19.3043 14.1032C19.3043 16.4687 19.3043 19.0313 19.3043 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.3043 16.3443C25.9719 16.3721 27.3071 16.1444 27.3071 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M27.9747 10.6732C27.0846 10.8309 25.3043 11.7377 25.3043 14.1032C25.3043 16.4687 25.3043 19.0313 25.3043 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M3 20L10.3333 20L25 20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M14 24L19.3333 24L30 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M29 20L29.3333 20L30 20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9 24H9.66667H11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        ) : (
          <>
            <path
              opacity="0.2"
              d="M7.21616 6.21616C9.15474 8.15474 9.72004 10.7325 8.47881 11.9737C7.23757 13.215 4.65983 12.6496 2.72125 10.7111C0.782677 8.7725 0.803099 5.60903 1.45861 4.95352C2.11411 4.29801 5.27759 4.27759 7.21616 6.21616Z"
              fill={color}
            />
            <path
              d="M7.30432 16.3443C7.97193 16.3721 9.30714 16.1444 9.30714 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9.97475 10.6732C9.0846 10.8309 7.30432 11.7377 7.30432 14.1032C7.30432 16.4687 7.30432 19.0313 7.30432 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M6.50906 6.92327C7.36381 7.77802 7.8814 8.74201 8.06313 9.57066C8.24769 10.4122 8.06879 10.9695 7.7717 11.2666C7.47461 11.5637 6.91732 11.7426 6.07574 11.558C5.2471 11.3763 4.28311 10.8587 3.42836 10.004C2.64982 9.22543 2.24096 8.171 2.10759 7.21323C2.04139 6.73782 2.04802 6.31842 2.09535 6.00759C2.12299 5.82605 2.15784 5.72205 2.17715 5.67206C2.22714 5.65275 2.33113 5.6179 2.51268 5.59026C2.82351 5.54293 3.24291 5.5363 3.71832 5.6025C4.67609 5.73587 5.73052 6.14474 6.50906 6.92327Z"
              stroke={color}
              strokeWidth="2"
            />
            <path
              d="M13.3043 16.3443C13.9719 16.3721 15.3071 16.1444 15.3071 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M15.9747 10.6732C15.0846 10.8309 13.3043 11.7377 13.3043 14.1032C13.3043 16.4687 13.3043 19.0313 13.3043 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.3043 16.3443C19.9719 16.3721 21.3071 16.1444 21.3071 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M21.9747 10.6732C21.0846 10.8309 19.3043 11.7377 19.3043 14.1032C19.3043 16.4687 19.3043 19.0313 19.3043 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.3043 16.3443C25.9719 16.3721 27.3071 16.1444 27.3071 15.0113"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M27.9747 10.6732C27.0846 10.8309 25.3043 11.7377 25.3043 14.1032C25.3043 16.4687 25.3043 19.0313 25.3043 20.0169"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <rect
              opacity="0.2"
              x="3"
              y="20"
              width="27"
              height="4"
              fill={color}
            />
            <path
              d="M3 20L10.3333 20L25 20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M14 24L19.3333 24L30 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M29 20L29.3333 20L30 20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9 24H9.66667H11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        )}
      </svg>
    );
  },
);
