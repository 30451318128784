import type { HTMLProps } from "react";
import type { RenderOptions } from "storyblok-rich-text-react-renderer";

import { type Locale, locales } from "~app/config";

import { getCleanPath } from "./api";
import type { PageContext } from "./page";

export type StoryblokLink = {
  id?: string;
  fieldtype?: string;
  anchor?: string;
  url: string;
  cached_url: string;
  target?: "_blank";
  locale?: Locale;
  rel?: "nofollow" | "noopener" | "noreferrer";
  title?: string;
  search?: string;
} & (
  | {
      linktype: "story";
      story: {
        id?: number;
        url: string;
        name?: string;
        uuid?: string;
        slug?: string;
        full_slug?: string;
      } | null;
    }
  | {
      linktype: "url" | "email" | "asset";
      story?: never;
    }
);

export function getLinkProps(
  link: StoryblokLink | null,
  context: PageContext,
): Pick<HTMLProps<HTMLAnchorElement>, "href" | "target" | "rel" | "title"> {
  if (!link) return {};

  if (link.linktype === "email") {
    return {
      href: `mailto:${link.url}`,
      title: link.title,
    };
  }

  if (link.linktype === "url") {
    return {
      href: link.anchor ? `${link.url}#${link.anchor}` : link.url,
      target: link.target ?? undefined,
      rel: link.rel,
      title: link.title,
    };
  }

  if (link.linktype === "story") {
    /**
     * The custom locale attribute is a way for the editor to link to pages outside the current page locale.
     * It should be used sparingly, opting for translating pages whenever possible.
     *
     * fx:
     *   current page url: `/de-de/betriebe`
     *   menu item href: `/certificate-sales` (locale attr: en)
     *
     * NOTE: this link will still use the translated slug, if set,
     * so if the intention is to link to the default slug, the translation needs to be deleted.
     */
    if (link.locale && !locales.includes(link.locale)) {
      throw new Error(
        [
          `Custom "locale" attribute: ${link.locale} does not have a valid value.`,
          `The accepted locales are: ${locales.join(", ")}`,
        ].join("\n"),
      );
    }

    const locale = link.locale || context.locale;

    // Use the url constructor to use its built-in validations and search params.
    const url = new URL("http://www.example.com");

    if (!link.story?.full_slug) return {};

    /**
     * Clean up the built-in locale/market parts from storyblok.
     * (we go custom and construct our own)
     */
    url.pathname = getCleanPath(link.story.full_slug);

    // Add the locale prefix if not global.
    if (locale !== "en") {
      url.pathname = `${locale.toLowerCase()}${url.pathname}`;
    }

    if (url.pathname && !url.pathname.endsWith("/")) {
      // Trailing slash is needed because that is our url style (see next.config)
      url.pathname = `${url.pathname}/`;
    }

    if (link.anchor) {
      url.hash = link.anchor;
    }

    if (link.search) {
      url.search = link.search;
    }

    /**
     * Make sure to append the editor params to the search,
     * so we ensure that the context is intact when navigating in preview mode.
     */
    if (context.editorParams) {
      for (const key in context.editorParams) {
        if (key === "_storyblok" && link.story.id) {
          url.searchParams.set("_storyblok", link.story.id?.toString() ?? "");
        } else {
          url.searchParams.set(key, context.editorParams[key]);
        }
      }
    }

    return {
      href: `${url.pathname}${url.search}${url.hash}`,
      target: link.target ?? undefined,
      rel: link.rel,
    };
  }

  if (link.linktype === "asset") {
    return {
      href: link.url,
      target: "_blank",
      title: link.title,
    };
  }

  throw new Error("Unsupported link type");
}

export type LinkMarkProps = Parameters<
  NonNullable<NonNullable<RenderOptions["markResolvers"]>["link"]>
>[1] & {
  story?: StoryblokLink["story"];
};

export function getLinkMarkProps(link: LinkMarkProps, context: PageContext) {
  const base = {
    url: link.href ?? "",
    cached_url: link.href ?? "",
    target: (link.target === "_blank"
      ? "_blank"
      : undefined) as StoryblokLink["target"],
  };

  if (link.linktype === "story") {
    return getLinkProps(
      {
        ...base,
        linktype: "story",
        story: link.story ?? null,
        anchor: link.anchor,
      },
      context,
    );
  }

  if (link.linktype === "email") {
    return getLinkProps(
      {
        ...base,
        linktype: "email",
      },
      context,
    );
  }

  if (link.linktype === "url") {
    return getLinkProps(
      {
        ...base,
        linktype: "url",
      },
      context,
    );
  }

  if (link.linktype === "asset") {
    return getLinkProps(
      {
        ...base,
        linktype: "asset",
      },
      context,
    );
  }

  throw new Error("Unsupported link type");
}
