import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { type MutableRefObject, useEffect, useRef } from "react";

type UsePictureInPictureProps = {
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  isAutoplay: boolean;
};

/**
 * Hook to add picture in picture when the user scrolls below a playing video.
 */
export function usePictureInPicture({
  videoRef,
  isAutoplay,
}: UsePictureInPictureProps) {
  const timeline = useRef<gsap.core.Timeline | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: ref is stable
  useEffect(() => {
    if (!videoRef.current) timeline.current?.kill();

    gsap.registerPlugin(ScrollTrigger);

    timeline.current = gsap.timeline({
      scrollTrigger: {
        trigger: videoRef.current,
        start: "bottom top", // Trigger when the element leaves the viewport (bottom hits top of viewport)
        onLeave: async () => {
          if (!videoRef.current) return;

          if (videoRef.current.paused === false) {
            try {
              await videoRef.current.requestPictureInPicture();
            } catch (err) {}
          }
        },
      },
    });

    return () => {
      timeline.current?.kill();
    };
  }, [isAutoplay]);
}
