import type { Locale } from "~app/config";

export function formatDateShort(
  date: Date | string | undefined | null,
  locale: Locale | undefined,
) {
  return formatDateTime(date, { locale, dateStyle: "medium" });
}

export function formatDate(
  date: Date | string | undefined | null,
  locale: Locale | undefined,
) {
  return formatDateTime(date, { locale, dateStyle: "long" });
}

type FormatDateTimeOptions = {
  locale: Locale | undefined;
  dateStyle?: "long" | "short" | "full" | "medium" | undefined;
  timeStyle?: "long" | "short" | "full" | "medium" | undefined;
};

// Converts timestamp/date to formatted dates with time according to locale and styles
function formatDateTime(
  date: string | Date | null | undefined,
  { locale, dateStyle, timeStyle }: FormatDateTimeOptions,
) {
  if (!date || !locale) return "";

  const dateObj = date instanceof Date ? date : new Date(date);
  const formatter = new Intl.DateTimeFormat(locale, {
    dateStyle,
    timeStyle,
  });

  return formatter.format(dateObj);
}
