"use client";

import { type ISbRichtext, storyblokEditable } from "@storyblok/react/rsc";
import Script from "next/script";
import { createRef, useEffect, useId, useState } from "react";

import type { SectionThemes } from "~components/types";
import { SeedWave } from "~components/ui/seed-wave";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";
import { getSurfaceVariant } from "~utils/style";

import { RichText } from "..";
import "../../../../app/hubspot.css";

export type HubspotFormBlok = {
  leading?: string;
  title?: string;
  text?: ISbRichtext;

  // Config
  region: string;
  portalId: string;
  formId: string;

  // Styles
  theme?: SectionThemes;
  backgroundVariant: "default" | "accent" | "lighter" | "darker" | "white";
  pattern?: boolean;
  patternColor?: string;
};

type HubspotFormProps = {
  blok: HubspotFormBlok;
  className?: string;
} & PageContext;

declare global {
  interface Window {
    hbspt: {
      forms: {
        create(opts: {
          region: string;
          portalId: string;
          formId: string;
          target: Element | string;
        }): void;
      };
    };
  }
}

export function HubspotForm({ blok, className, ...context }: HubspotFormProps) {
  const id = useId();

  // Build a prefixed, cleaner id for the html element.
  const targetId = `hubspot-${id.replace(/:/g, "")}`;

  const [isLibLoaded, setIsLibLoaded] = useState(false);
  const embedRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!isLibLoaded || !targetId) return;

    window.hbspt.forms.create({
      region: blok.region,
      portalId: blok.portalId,
      formId: blok.formId,
      target: `#${targetId}`,
    });
  }, [targetId, blok, isLibLoaded]);

  return (
    <>
      <Script
        type="text/javascript"
        src="//js-eu1.hsforms.net/forms/embed/v2.js"
        onLoad={() => setIsLibLoaded(true)}
      />

      <section
        className={cn(
          "[--hubspot-form-max-w:100%] [--hubspot-form-px:0px]",
          "relative flex flex-col place-items-center gap-md px-[var(--hubspot-form-px)] py-lg-xl",
          blok.theme,
          getSurfaceVariant(blok.backgroundVariant),
          className,
        )}
        {...storyblokEditable(blok)}
      >
        <div className="z-10 flex w-full max-w-[var(--hubspot-form-max-w)] flex-col gap-sm">
          {blok.leading && (
            <h2 className="-mb-xs t-prosi-md max-w-sub text-pretty text-content-heading leading-tight">
              {blok.leading}
            </h2>
          )}
          {blok.title && (
            <h1 className="t-strong-xl max-w-title text-balance text-content-heading">
              {blok.title}
            </h1>
          )}

          {blok.text && (
            <RichText
              className="max-w-text text-pretty leading-normal"
              data={blok.text}
              {...context}
            />
          )}
        </div>

        <div
          ref={embedRef}
          id={targetId}
          className="z-10 w-full max-w-[var(--hubspot-form-max-w)]"
        />

        {blok.pattern ? (
          <SeedWave
            className="absolute bottom-[0] z-0 aspect-[3/2] w-full bg-decor/accent max-md:left-[-10%]"
            seedPatternColor={blok.patternColor as string | undefined}
          />
        ) : null}
      </section>
    </>
  );
}
