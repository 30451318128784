import { type ClassValue, clsx } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";
const tailwindConfig = require("../../tailwind.config");

export const tailwindMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": Object.keys(tailwindConfig.theme.fontSize).map(
        key => `text-${key}`,
      ),
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return tailwindMerge(clsx(inputs));
}
