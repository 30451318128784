export function getSurfaceVariant(
  backgroundVariant: unknown,
  fallback?: string,
) {
  switch (backgroundVariant) {
    case "lighter":
      return "bg-surface/100";
    case "darker":
      return "bg-surface/300";
    case "accent":
      return "bg-surface/accent";
    case "white":
      return "bg-others-white";
    case "black":
      return "bg-others-black";
    default:
      return fallback ?? "bg-surface/200";
  }
}
