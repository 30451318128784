import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const StackPlant = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A stack with a plant on top</title>
        {props.weight === "regular" ? (
          <>
            <g clipPath="url(#clip0_4602_53762)">
              <path
                d="M26 11L30 13L16 21L2 13L6 10.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 18L16 26L30 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 9C16 11.8 16 14.8333 16 16"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M14.2979 6.21723C15.1527 7.07198 15.6703 8.03597 15.852 8.86462C16.0365 9.70619 15.8576 10.2635 15.5606 10.5606C15.2635 10.8577 14.7062 11.0366 13.8646 10.852C13.0359 10.6703 12.072 10.1527 11.2172 9.29793C10.4387 8.51939 10.0298 7.46496 9.89644 6.50719C9.83024 6.03178 9.83687 5.61238 9.8842 5.30155C9.91184 5.12001 9.94668 5.01601 9.966 4.96602C10.016 4.94671 10.12 4.91186 10.3015 4.88422C10.6124 4.83689 11.0318 4.83026 11.5072 4.89646C12.4649 5.02983 13.5194 5.4387 14.2979 6.21723Z"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M20.1461 11.1562C19.4308 11.8715 18.6293 12.299 17.9506 12.4479C17.2589 12.5996 16.839 12.445 16.6312 12.2372C16.4234 12.0294 16.2688 11.6095 16.4205 10.9178C16.5693 10.2391 16.9969 9.43758 17.7122 8.72229C18.3512 8.08322 19.2221 7.74335 20.0195 7.63233C20.4146 7.5773 20.7596 7.58343 21.0101 7.62158C21.0992 7.63514 21.1626 7.65044 21.2055 7.66293C21.218 7.70578 21.2333 7.76923 21.2468 7.85828C21.285 8.10881 21.2911 8.45375 21.2361 8.84894C21.125 9.64626 20.7852 10.5172 20.1461 11.1562Z"
                stroke={color}
                strokeWidth="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_4602_53762">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        ) : (
          <>
            <g clipPath="url(#clip0_4593_53275)">
              <path
                opacity="0.2"
                d="M5.03885 11.2635C3.69522 12.0313 3.69522 13.9687 5.03885 14.7365L15.0077 20.433C15.6226 20.7843 16.3774 20.7843 16.9923 20.433L26.9611 14.7365C28.3048 13.9687 28.3048 12.0313 26.9611 11.2635L16.9923 5.56702C16.3774 5.21566 15.6226 5.21566 15.0077 5.56702L5.03885 11.2635Z"
                fill={color}
              />
              <path
                d="M26 11L30 13L16 21L2 13L6 10.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 18L16 26L30 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 9C16 11.8 16 14.8333 16 16"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M14.2979 6.21723C15.1527 7.07198 15.6703 8.03597 15.852 8.86462C16.0365 9.70619 15.8576 10.2635 15.5606 10.5606C15.2635 10.8577 14.7062 11.0366 13.8646 10.852C13.0359 10.6703 12.072 10.1527 11.2172 9.29793C10.4387 8.51939 10.0298 7.46496 9.89644 6.50719C9.83024 6.03178 9.83687 5.61238 9.8842 5.30155C9.91184 5.12001 9.94668 5.01601 9.966 4.96602C10.016 4.94671 10.12 4.91186 10.3015 4.88422C10.6124 4.83689 11.0318 4.83026 11.5072 4.89646C12.4649 5.02983 13.5194 5.4387 14.2979 6.21723Z"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M20.1461 11.1562C19.4308 11.8715 18.6293 12.299 17.9506 12.4479C17.2589 12.5996 16.839 12.445 16.6312 12.2372C16.4234 12.0294 16.2688 11.6095 16.4205 10.9178C16.5693 10.2391 16.9969 9.43758 17.7122 8.72229C18.3512 8.08322 19.2221 7.74335 20.0195 7.63233C20.4146 7.5773 20.7596 7.58343 21.0101 7.62158C21.0992 7.63514 21.1626 7.65044 21.2055 7.66293C21.218 7.70578 21.2333 7.76923 21.2468 7.85828C21.285 8.10881 21.2911 8.45375 21.2361 8.84894C21.125 9.64626 20.7852 10.5172 20.1461 11.1562Z"
                stroke={color}
                strokeWidth="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_4593_53275">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    );
  },
);
