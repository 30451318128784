"use client";

import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import Cookies from "js-cookie";
import { type ReactNode, createContext, useCallback, useEffect } from "react";

import { useSectionTracking } from "~utils";

import { ampli } from "./generated";

type AmplitudeProps = {
  apiKey: string;
  cookiebotEnabled: boolean;
  sessionReplayEnabled: boolean;
};

const AmplitudeContext = createContext<AmplitudeProps | null>(null);

type AmplitudeProviderProps = AmplitudeProps & {
  children: ReactNode;
};

export function AmplitudeProvider({
  children,
  apiKey,
  cookiebotEnabled,
  sessionReplayEnabled,
}: AmplitudeProviderProps) {
  useSectionTracking();

  const onUpdate = useCallback(async () => {
    const isOptedIn =
      window.Cookiebot?.consent?.statistics === true || !cookiebotEnabled;

    // Wait with loading until the user opts in.
    if (!ampli.isLoaded && isOptedIn) {
      ampli.load({
        client: {
          apiKey,
          configuration: {
            defaultTracking: true,
            transport: "beacon",
          },
        },
      });

      // Adds session replays to get detailed insights on user behavior.
      ampli.client?.add(
        sessionReplayPlugin({
          sampleRate: sessionReplayEnabled ? 0.5 : 0, // 50% of sessions are captured
        }),
      );

      return;
    }

    // Opt out and clean up cookies when the user revokes consent.
    if (ampli.isLoaded && !isOptedIn) {
      ampli.client.setOptOut(true);

      const key = apiKey.slice(0, 10);

      Cookies.remove(`AMP_${key}`, { path: "", domain: ".agreena.com" });
      Cookies.remove(`AMP_MKTG_${key}`, { path: "", domain: ".agreena.com" });
    }
  }, [apiKey, cookiebotEnabled, sessionReplayEnabled]);

  /**
   * Set up cookiebot listeners for loaded/updated actions,
   * and kick it off if already loaded or cookiebot is disabled.
   */
  useEffect(() => {
    // If we are on an environment with no cookie support, early exit.
    if (!cookiebotEnabled) {
      onUpdate();
      return;
    }

    /**
     * Whenever the user updates constent, update the amplitude state.
     * This is also fired on subsequent visits to the page after a user
     * has already made a choice.
     */
    window.addEventListener("CookiebotOnConsentReady", onUpdate);

    /**
     * Depending on timing, this script can run when Cookiebot is already initiliased,
     * meaning the "CookiebotOnConsentReady" event listener is too late.
     * In that case, fire the update listener.
     */
    if (window.Cookiebot) onUpdate();

    return () => {
      window.removeEventListener("CookiebotOnConsentReady", onUpdate);
    };
  }, [cookiebotEnabled, onUpdate]);

  return (
    <AmplitudeContext.Provider
      value={{ apiKey, cookiebotEnabled, sessionReplayEnabled }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
}
