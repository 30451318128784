import * as icons from "@phosphor-icons/react/dist/ssr";
import type { HTMLAttributes } from "react";
import { customIcons } from "./custom";

type CustomIconName = keyof typeof customIcons;

type PhosphorIconName = Exclude<
  keyof typeof icons,
  "IconContext" | "IconBase" | "SSR"
>;

export type IconName = CustomIconName | PhosphorIconName;

type IconProps = {
  name: IconName;
  weight?: icons.IconProps["weight"];
} & Omit<HTMLAttributes<SVGElement>, "size" | "color">;

export function Icon({ name, weight = "regular", ...rest }: IconProps) {
  const Comp =
    customIcons[name as CustomIconName] ?? icons[name as PhosphorIconName];

  if (!Comp) throw new Error(`An Icon by the name: '${name}' does not exist`);

  return <Comp {...rest} size="1em" color="currentColor" weight={weight} />;
}
