import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const SoilMound = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A mound of soil</title>
        {props.weight === "regular" ? (
          <>
            <path
              opacity="0.2"
              d="M14.6126 6.76298L9.5 15C8.26642 15 7.13065 15.6715 6.53616 16.7524L1.99999 25L15 25L24 25L30 25L21.6321 7.17281C20.2835 4.2997 16.2864 4.06633 14.6126 6.76298Z"
              fill={color}
            />
            <path
              d="M9 15H10.3431C11.404 15 12.4214 15.4214 13.1716 16.1716L14 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19 25H21"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 29H5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9 29L31 29"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M15 25L1.99999 25L6.53616 16.7524C7.13065 15.6715 8.26642 15 9.5 15V15L14.6126 6.76298C16.2864 4.06633 20.2835 4.2997 21.6321 7.17281L30 25L24 25"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 24C8 23.2044 8.31607 22.4413 8.87868 21.8787C9.44129 21.3161 10.2044 21 11 21"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M16.4966 10.4966C16.7418 10.2514 17.0563 10.0873 17.3977 10.0264C17.7391 9.96545 18.0909 10.0106 18.4058 10.1559"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.918 17.7376C20.0599 17.4337 20.2886 17.1787 20.5754 17.0048C20.8621 16.8309 21.194 16.7459 21.529 16.7606C21.8641 16.7753 22.1872 16.8891 22.4577 17.0874C22.7281 17.2858 22.9336 17.5599 23.0482 17.8751"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.8947 18.8947C25.6761 19.1132 25.402 19.2679 25.102 19.3419C24.802 19.416 24.4874 19.4066 24.1923 19.3149C23.8971 19.2231 23.6327 19.0525 23.4275 18.8213C23.2224 18.5902 23.0843 18.3074 23.0282 18.0035"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        ) : (
          <>
            <path
              d="M9 15H10.3431C11.404 15 12.4214 15.4214 13.1716 16.1716L14 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19 25H21"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 29H5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9 29L31 29"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M15 25L1.99999 25L6.53616 16.7524C7.13065 15.6715 8.26642 15 9.5 15V15L14.6126 6.76298C16.2864 4.06633 20.2835 4.2997 21.6321 7.17281L30 25L24 25"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 24C8 23.2044 8.31607 22.4413 8.87868 21.8787C9.44129 21.3161 10.2044 21 11 21"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M16.4966 10.4966C16.7418 10.2514 17.0563 10.0873 17.3977 10.0264C17.7391 9.96545 18.0909 10.0106 18.4058 10.1559"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.918 17.7376C20.0599 17.4337 20.2886 17.1787 20.5754 17.0048C20.8621 16.8309 21.194 16.7459 21.529 16.7606C21.8641 16.7753 22.1872 16.8891 22.4577 17.0874C22.7281 17.2858 22.9336 17.5599 23.0482 17.8751"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.8947 18.8947C25.6761 19.1132 25.402 19.2679 25.102 19.3419C24.802 19.416 24.4874 19.4066 24.1923 19.3149C23.8971 19.2231 23.6327 19.0525 23.4275 18.8213C23.2224 18.5902 23.0843 18.3074 23.0282 18.0035"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        )}
      </svg>
    );
  },
);
