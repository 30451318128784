// TODO: pull this from storyblok lib
export type StoryblokAsset =
  | {
      id: number | null;
      name: string;
      focus: string | null;
      title: string | null;
      source?: string | null;
      alt: string;
      filename: string;
      copyright?: string | null;
      fieldtype?: string;
      meta_data?: object;
      is_private?: boolean;
      is_external_url?: boolean;
    }
  | {
      // This is what storyblok uses for an empty image
      alt: null;
      copyright: null;
      fieldtype: string;
      filename: null;
      focus: null;
      id: null;
      name: string;
      title: null;
      is_external_url: boolean;
      meta_data: object;
    };

/**
 * Gets the dimensions of a storyblok image from its filename.
 */

export function getFilenameDimensions(filename: string | undefined | null) {
  if (!filename) return null;

  /**
   * Storyblok does not have a json width/height property,
   * but always places image dimensions at the same position, in this /{width}x{height}/ format.
   */
  const matcher = /[/](\d+x\d+)[/.]/;
  const matches = filename.match(matcher);
  if (!matches) return null;

  const dimensionsPart = matches[1];
  const [rawWidth, rawHeight] = dimensionsPart ? dimensionsPart.split("x") : [];

  const width = Number.parseInt(rawWidth, 10);
  const height = Number.parseInt(rawHeight, 10);

  if (Number.isNaN(width) || Number.isNaN(height)) return null;

  return { width, height };
}

/**
 * Gets the dimensions of a storyblok image from its filename.
 */
export function getImageDimensions(asset: StoryblokAsset | undefined) {
  return getFilenameDimensions(asset?.filename);
}
