import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const RecyclePlant = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A plant with 2 arrows pointing in a circle around it</title>
        {props.weight === "regular" ? (
          <>
            <g clipPath="url(#clip0_4610_201351)">
              <path
                d="M25 16L28 19L31 16"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 16L4 13L7 16"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 13V16C3.99906 18.5406 4.8045 21.016 6.30033 23.0696C7.79615 25.1232 9.90517 26.6491 12.3236 27.4274C14.7421 28.2058 17.3452 28.1965 19.758 27.4008C22.1709 26.6052 24.2689 25.0643 25.75 23"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 19V16C28.0009 13.4594 27.1955 10.984 25.6997 8.9304C24.2038 6.87679 22.0948 5.35093 19.6764 4.57257C17.2579 3.79421 14.6548 3.80353 12.242 4.59918C9.82913 5.39483 7.73109 6.93575 6.25 9.00001"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.0703 15.0101C16.0703 17.8101 16.0703 20.8435 16.0703 22.0101"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M14.3682 12.2274C15.223 13.0821 15.7406 14.0461 15.9223 14.8747C16.1068 15.7163 15.928 16.2736 15.6309 16.5707C15.3338 16.8678 14.7765 17.0467 13.9349 16.8621C13.1063 16.6804 12.1423 16.1628 11.2875 15.3081C10.509 14.5295 10.1001 13.4751 9.96675 12.5173C9.90055 12.0419 9.90718 11.6225 9.95451 11.3117C9.98215 11.1301 10.017 11.0261 10.0363 10.9762C10.0863 10.9568 10.1903 10.922 10.3718 10.8943C10.6827 10.847 11.1021 10.8404 11.5775 10.9066C12.5353 11.04 13.5897 11.4488 14.3682 12.2274Z"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M20.1142 16.0979C19.2931 16.6886 18.4331 16.9814 17.7393 17.019C17.0322 17.0573 16.6427 16.8371 16.4711 16.5985C16.2995 16.36 16.2146 15.9206 16.4757 15.2624C16.7319 14.6165 17.283 13.8944 18.1042 13.3036C18.8378 12.7759 19.7521 12.5807 20.5569 12.5996C20.9558 12.6089 21.2953 12.6706 21.5364 12.7486C21.6221 12.7763 21.6822 12.8016 21.7225 12.8208C21.7279 12.8651 21.7328 12.9302 21.7318 13.0203C21.7291 13.2737 21.6796 13.6151 21.5617 13.9963C21.3236 14.7653 20.8479 15.5701 20.1142 16.0979Z"
                stroke={color}
                strokeWidth="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_4610_201351">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        ) : (
          <>
            <g clipPath="url(#clip0_4610_201368)">
              <path
                opacity="0.2"
                d="M15.0753 11.5203C17.0139 13.4588 17.5792 16.0366 16.338 17.2778C15.0967 18.519 12.519 17.9537 10.5804 16.0152C8.64184 14.0766 8.66226 10.9131 9.31777 10.2576C9.97328 9.6021 13.1367 9.58168 15.0753 11.5203Z"
                fill={color}
              />
              <path
                opacity="0.2"
                d="M20.6982 16.9097C18.7929 18.2803 16.5369 18.4024 15.6593 17.1825C14.7817 15.9625 15.6149 13.8625 17.5202 12.4919C19.4255 11.1212 22.0956 11.5748 22.5591 12.219C23.0226 12.8633 22.6035 15.539 20.6982 16.9097Z"
                fill={color}
              />
              <path
                d="M25 16L28 19L31 16"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 16L4 13L7 16"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 13V16C3.99906 18.5406 4.8045 21.016 6.30033 23.0696C7.79615 25.1232 9.90517 26.6491 12.3236 27.4274C14.7421 28.2058 17.3452 28.1965 19.758 27.4008C22.1709 26.6052 24.2689 25.0643 25.75 23"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 19V16C28.0009 13.4594 27.1955 10.984 25.6997 8.9304C24.2038 6.87679 22.0948 5.35093 19.6764 4.57257C17.2579 3.79421 14.6548 3.80353 12.242 4.59918C9.82913 5.39483 7.73109 6.93575 6.25 9.00001"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.0703 15.0101C16.0703 17.8101 16.0703 20.8435 16.0703 22.0101"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M14.3682 12.2274C15.223 13.0821 15.7406 14.0461 15.9223 14.8747C16.1068 15.7163 15.928 16.2736 15.6309 16.5707C15.3338 16.8678 14.7765 17.0467 13.9349 16.8621C13.1063 16.6804 12.1423 16.1628 11.2875 15.3081C10.509 14.5295 10.1001 13.4751 9.96675 12.5173C9.90055 12.0419 9.90718 11.6225 9.95451 11.3117C9.98215 11.1301 10.017 11.0261 10.0363 10.9762C10.0863 10.9568 10.1903 10.922 10.3718 10.8943C10.6827 10.847 11.1021 10.8404 11.5775 10.9066C12.5353 11.04 13.5897 11.4488 14.3682 12.2274Z"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M20.1142 16.0979C19.2931 16.6886 18.4331 16.9814 17.7393 17.019C17.0322 17.0573 16.6427 16.8371 16.4711 16.5985C16.2995 16.36 16.2146 15.9206 16.4757 15.2624C16.7319 14.6165 17.283 13.8944 18.1042 13.3036C18.8378 12.7759 19.7521 12.5807 20.5569 12.5996C20.9558 12.6089 21.2953 12.6706 21.5364 12.7486C21.6221 12.7763 21.6822 12.8016 21.7225 12.8208C21.7279 12.8651 21.7328 12.9302 21.7318 13.0203C21.7291 13.2737 21.6796 13.6151 21.5617 13.9963C21.3236 14.7653 20.8479 15.5701 20.1142 16.0979Z"
                stroke={color}
                strokeWidth="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_4610_201368">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    );
  },
);
