import bg from "../../public/flags/bg.svg";
import cz from "../../public/flags/cz.svg";
import de from "../../public/flags/de.svg";
import dk from "../../public/flags/dk.svg";
import ee from "../../public/flags/ee.svg";
import es from "../../public/flags/es.svg";
import fi from "../../public/flags/fi.svg";
import fr from "../../public/flags/fr.svg";
import hu from "../../public/flags/hu.svg";
import lt from "../../public/flags/lt.svg";
import lv from "../../public/flags/lv.svg";
import no from "../../public/flags/no.svg";
import pl from "../../public/flags/pl.svg";
import ro from "../../public/flags/ro.svg";
import ua from "../../public/flags/ua.svg";
import uk from "../../public/flags/uk.svg";
import us from "../../public/flags/us.svg";

// biome-ignore lint/suspicious/noExplicitAny: Next static imports are not typed properly
export function getFlagIcon(countryCode: string): any | null {
  switch (countryCode) {
    case "bg":
      return bg;
    case "cz":
      return cz;
    case "de":
      return de;
    case "dk":
      return dk;
    case "ee":
      return ee;
    case "es":
      return es;
    case "fi":
      return fi;
    case "fr":
      return fr;
    case "hu":
      return hu;
    case "lt":
      return lt;
    case "lv":
      return lv;
    case "no":
      return no;
    case "pl":
      return pl;
    case "ro":
      return ro;
    case "ua":
      return ua;
    case "uk":
      return uk;
    case "us":
      return us;
    default:
      return null;
  }
}
