import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const CO2Certificate = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>The text "CO2" on a ribbon</title>
        {props.weight === "regular" ? (
          <>
            <defs>
              <clipPath id="clip0_4610_130004">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
            <g clipPath="url(#clip0_4610_130004)">
              <path
                d="M8 22V29L16 25L24 29V22"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.1609 16.8476C11.1551 16.5825 11.0441 16.3306 10.8525 16.1472C10.6609 15.9639 10.4043 15.8642 10.1392 15.8701C9.87407 15.876 9.62214 15.9869 9.43879 16.1785C9.27647 16.3571 9.07952 16.5009 8.8599 16.601C8.64029 16.7012 8.4026 16.7556 8.16129 16.761C7.05879 16.761 6.16129 15.636 6.16129 14.261C6.16129 12.886 7.05879 11.761 8.16129 11.761C8.4026 11.7664 8.64029 11.8208 8.8599 11.921C9.07952 12.0211 9.27647 12.1649 9.43879 12.3435C9.62303 12.5311 9.87377 12.6387 10.1367 12.6428C10.3997 12.6469 10.6536 12.5473 10.8437 12.3655C11.0337 12.1837 11.1444 11.9343 11.1519 11.6715C11.1595 11.4086 11.0631 11.1533 10.8838 10.961C10.5356 10.5862 10.1145 10.2864 9.64637 10.08C9.17823 9.8737 8.67288 9.76513 8.16129 9.76099C5.95504 9.76099 4.16129 11.7797 4.16129 14.261C4.16129 16.7422 5.95504 18.761 8.16129 18.761C8.67288 18.7568 9.17823 18.6483 9.64637 18.4419C10.1145 18.2356 10.5356 17.9358 10.8838 17.561C11.0671 17.3694 11.1668 17.1128 11.1609 16.8476Z"
                fill={color}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1613 9.76099C13.955 9.76099 12.1613 11.7797 12.1613 14.261C12.1613 16.7422 13.955 18.761 16.1613 18.761C18.3675 18.761 20.1613 16.7422 20.1613 14.261C20.1613 11.7797 18.3675 9.76099 16.1613 9.76099ZM16.1613 11.761C15.0582 11.761 14.1613 12.8825 14.1613 14.261C14.1613 15.6395 15.0582 16.761 16.1613 16.761C17.2644 16.761 18.1613 15.6395 18.1613 14.261C18.1613 12.8825 17.2644 11.761 16.1613 11.761Z"
                fill={color}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3815 17.1307L24.7594 12.9392C24.8246 12.862 24.8711 12.7754 24.8979 12.6847C24.9246 12.5942 24.9315 12.5003 24.9186 12.4079C24.9057 12.3155 24.8729 12.2249 24.8208 12.1416C24.7686 12.0581 24.6977 11.983 24.6107 11.922C24.5237 11.8609 24.4229 11.8155 24.3139 11.7897C24.2048 11.7639 24.0909 11.7586 23.9792 11.7741C23.8675 11.7897 23.7615 11.8257 23.6674 11.8789C23.5733 11.9321 23.4938 12.001 23.4321 12.0801L23.4272 12.0864C23.3787 12.1463 23.3408 12.2115 23.3142 12.2797C23.2673 12.4076 23.1945 12.5238 23.1017 12.6223C23.0076 12.7221 22.8947 12.8018 22.7706 12.8581C22.6465 12.9145 22.5128 12.9467 22.377 12.9539C22.2413 12.9611 22.105 12.9431 21.9757 12.9005C21.8464 12.8579 21.7257 12.7912 21.6211 12.7028C21.5165 12.6144 21.4299 12.5058 21.368 12.3824C21.3061 12.2588 21.2706 12.1236 21.2648 11.9849C21.259 11.8476 21.2824 11.7112 21.3329 11.584C21.4767 11.2078 21.705 10.8675 22.0001 10.585C22.296 10.3018 22.6522 10.0833 23.0434 9.94269C23.4346 9.80206 23.8528 9.74207 24.2698 9.76618C24.6869 9.79029 25.0945 9.89803 25.465 10.0832C25.8356 10.2683 26.1612 10.5271 26.4182 10.8435C26.6754 11.1601 26.8575 11.5267 26.9502 11.9188C27.0428 12.311 27.0432 12.7176 26.9514 13.11C26.8596 13.5021 26.6783 13.8689 26.422 14.1859L24.3651 16.738H25.9663C26.2365 16.738 26.5013 16.8375 26.7011 17.0235C26.902 17.2104 27.0213 17.4708 27.0213 17.7495C27.0213 18.0282 26.902 18.2886 26.7011 18.4755C26.5013 18.6614 26.2365 18.761 25.9663 18.761H22.2163C22.0265 18.761 21.8386 18.7119 21.6734 18.6169C21.5079 18.5218 21.3699 18.3831 21.2785 18.2131C21.1869 18.0427 21.1471 17.8494 21.1658 17.6558C21.1846 17.4623 21.2607 17.2806 21.3815 17.1307Z"
                fill={color}
              />
              <path
                d="M8.16129 6.76099V4.76099C8.16129 4.2087 8.60901 3.76099 9.16129 3.76099H23.1613C23.7136 3.76099 24.1613 4.2087 24.1613 4.76099V6.76099"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </g>
          </>
        ) : (
          <>
            <g clipPath="url(#clip0_4610_200357)">
              <path
                opacity="0.2"
                d="M16 25L24 29V22H8V29L16 25Z"
                fill={color}
              />
              <path
                d="M8 22V29L16 25L24 29V22"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.1609 16.8476C11.1551 16.5825 11.0441 16.3306 10.8525 16.1472C10.6609 15.9639 10.4043 15.8642 10.1392 15.8701C9.87407 15.876 9.62214 15.9869 9.43879 16.1785C9.27647 16.3571 9.07952 16.5009 8.8599 16.601C8.64029 16.7012 8.4026 16.7556 8.16129 16.761C7.05879 16.761 6.16129 15.636 6.16129 14.261C6.16129 12.886 7.05879 11.761 8.16129 11.761C8.4026 11.7664 8.64029 11.8208 8.8599 11.921C9.07952 12.0211 9.27647 12.1649 9.43879 12.3435C9.62303 12.5311 9.87377 12.6387 10.1367 12.6428C10.3997 12.6469 10.6536 12.5473 10.8437 12.3655C11.0337 12.1837 11.1444 11.9343 11.1519 11.6715C11.1595 11.4086 11.0631 11.1533 10.8838 10.961C10.5356 10.5862 10.1145 10.2864 9.64637 10.08C9.17823 9.8737 8.67288 9.76513 8.16129 9.76099C5.95504 9.76099 4.16129 11.7797 4.16129 14.261C4.16129 16.7422 5.95504 18.761 8.16129 18.761C8.67288 18.7568 9.17823 18.6483 9.64637 18.4419C10.1145 18.2356 10.5356 17.9358 10.8838 17.561C11.0671 17.3694 11.1668 17.1128 11.1609 16.8476Z"
                fill={color}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1613 9.76099C13.955 9.76099 12.1613 11.7797 12.1613 14.261C12.1613 16.7422 13.955 18.761 16.1613 18.761C18.3675 18.761 20.1613 16.7422 20.1613 14.261C20.1613 11.7797 18.3675 9.76099 16.1613 9.76099ZM16.1613 11.761C15.0582 11.761 14.1613 12.8825 14.1613 14.261C14.1613 15.6395 15.0582 16.761 16.1613 16.761C17.2644 16.761 18.1613 15.6395 18.1613 14.261C18.1613 12.8825 17.2644 11.761 16.1613 11.761Z"
                fill={color}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3815 17.1307L24.7594 12.9392C24.8246 12.862 24.8711 12.7754 24.8979 12.6847C24.9246 12.5942 24.9315 12.5003 24.9186 12.4079C24.9057 12.3155 24.8729 12.2249 24.8208 12.1416C24.7686 12.0581 24.6977 11.983 24.6107 11.922C24.5237 11.8609 24.4229 11.8155 24.3139 11.7897C24.2048 11.7639 24.0909 11.7586 23.9792 11.7741C23.8675 11.7897 23.7615 11.8257 23.6674 11.8789C23.5733 11.9321 23.4938 12.001 23.4321 12.0801L23.4272 12.0864C23.3787 12.1463 23.3408 12.2115 23.3142 12.2797C23.2673 12.4076 23.1945 12.5238 23.1017 12.6223C23.0076 12.7221 22.8947 12.8018 22.7706 12.8581C22.6465 12.9145 22.5128 12.9467 22.377 12.9539C22.2413 12.9611 22.105 12.9431 21.9757 12.9005C21.8464 12.8579 21.7257 12.7912 21.6211 12.7028C21.5165 12.6144 21.4299 12.5058 21.368 12.3824C21.3061 12.2588 21.2706 12.1236 21.2648 11.9849C21.259 11.8476 21.2824 11.7112 21.3329 11.584C21.4767 11.2078 21.705 10.8675 22.0001 10.585C22.296 10.3018 22.6522 10.0833 23.0434 9.94269C23.4346 9.80206 23.8528 9.74207 24.2698 9.76618C24.6869 9.79029 25.0945 9.89803 25.465 10.0832C25.8356 10.2683 26.1612 10.5271 26.4182 10.8435C26.6754 11.1601 26.8575 11.5267 26.9502 11.9188C27.0428 12.311 27.0432 12.7176 26.9514 13.11C26.8596 13.5021 26.6783 13.8689 26.422 14.1859L24.3651 16.738H25.9663C26.2365 16.738 26.5013 16.8375 26.7011 17.0235C26.902 17.2104 27.0213 17.4708 27.0213 17.7495C27.0213 18.0282 26.902 18.2886 26.7011 18.4755C26.5013 18.6614 26.2365 18.761 25.9663 18.761H22.2163C22.0265 18.761 21.8386 18.7119 21.6734 18.6169C21.5079 18.5218 21.3699 18.3831 21.2785 18.2131C21.1869 18.0427 21.1471 17.8494 21.1658 17.6558C21.1846 17.4623 21.2607 17.2806 21.3815 17.1307Z"
                fill={color}
              />
              <path
                d="M8.16129 6.76099V4.76099C8.16129 4.2087 8.60901 3.76099 9.16129 3.76099H23.1613C23.7136 3.76099 24.1613 4.2087 24.1613 4.76099V6.76099"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4610_200357">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    );
  },
);
