import plugin from "tailwindcss/plugin";

export default plugin(({ theme, addComponents }) => {
  addComponents({
    // Section
    ".section": {
      "--section-max-w": "58em",
      "--section-px": theme("spacing.sm"),
      "--section-py": theme("spacing.3xl-4xl"),
      "--section-offset-x":
        "max(calc(50vw - (var(--section-max-w) / 2)), var(--section-px))",

      color: theme("colors.content.text"),

      display: "grid",
      "grid-template-columns":
        "var(--section-offset-x) minmax(auto, var(--section-max-w)) var(--section-offset-x)",
      "row-gap": theme("spacing.xl-2xl"),

      padding: "var(--section-py) 0",

      "background-color": theme("colors.surface/100"),

      "@media (min-width: 640px)": {
        "--section-px": theme("spacing.2xl-3xl"),
      },

      "@media (min-width: 1024px)": {
        "--section-px": theme("spacing.3xl-4xl"),
      },

      "& > *": {
        "grid-column": "2/3",
      },
    },
    ".section-item-full": {
      "grid-column": "1/-1",
      width: "100%",
    },
  });
});
