import type { ButtonHTMLAttributes } from "react";
import { cn } from "~utils";
import { Icon, type IconName } from "../icon";
import styles from "./icon-button.module.css";

type ButtonProps = {
  animated?: boolean | "right" | "left" | "up" | "down";
  variant?: "primary" | "secondary";
  name: IconName;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function IconButton({
  className,
  animated,
  variant = "primary",
  name,
  type,
  ...rest
}: ButtonProps) {
  return (
    <button
      type={type || "button"}
      className={cn(
        styles.button,
        styles[variant],
        animated && styles.animated,
        animated === "left" && styles.left,
        animated === "up" && styles.up,
        animated === "down" && styles.down,
        className,
      )}
      {...rest}
    >
      <div className={styles.inner}>
        {animated && (
          <Icon name={name} className={cn(styles["pre-arrow"], styles.icon)} />
        )}

        <Icon name={name} className={cn(styles["post-arrow"], styles.icon)} />
      </div>
    </button>
  );
}
