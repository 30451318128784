import type { ReactNode } from "react";

export function isWrapperNode(children: ReactNode) {
  // Unwrap elements when there is no need for extra wrapper
  if (
    Array.isArray(children) &&
    children.length === 1 &&
    typeof children[0]?.type === "object"
  ) {
    return true;
  }

  return false;
}
