import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const FileCO2 = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>The text "CO2" with a file behind it</title>
        {props.weight === "regular" ? (
          <>
            <path
              d="M6 15C6.26522 15 6.51957 14.8946 6.70711 14.7071C6.89464 14.5196 7 14.2652 7 14V5H18V11C18 11.2652 18.1054 11.5196 18.2929 11.7071C18.4804 11.8946 18.7348 12 19 12H25V14C25 14.2652 25.1054 14.5196 25.2929 14.7071C25.4804 14.8946 25.7348 15 26 15C26.2652 15 26.5196 14.8946 26.7071 14.7071C26.8946 14.5196 27 14.2652 27 14V11C27.0001 10.8686 26.9743 10.7385 26.9241 10.6172C26.8739 10.4958 26.8003 10.3854 26.7075 10.2925L19.7075 3.2925C19.6146 3.19967 19.5042 3.12605 19.3829 3.07586C19.2615 3.02568 19.1314 2.9999 19 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V14C5 14.2652 5.10536 14.5196 5.29289 14.7071C5.48043 14.8946 5.73478 15 6 15ZM20 6.41375L23.5863 10H20V6.41375Z"
              fill={color}
            />
            <path
              d="M10.9996 25.0867C10.9938 24.8215 10.8828 24.5696 10.6912 24.3862C10.4996 24.2029 10.243 24.1033 9.9779 24.1091C9.71278 24.115 9.46084 24.2259 9.2775 24.4175C9.11518 24.5961 8.91822 24.7399 8.69861 24.84C8.47899 24.9402 8.24131 24.9946 8 25C6.8975 25 6 23.875 6 22.5C6 21.125 6.8975 20 8 20C8.24131 20.0054 8.47899 20.0598 8.69861 20.16C8.91822 20.2601 9.11518 20.4039 9.2775 20.5825C9.46174 20.7702 9.71248 20.8777 9.97543 20.8818C10.2384 20.8859 10.4924 20.7863 10.6824 20.6045C10.8724 20.4227 10.9831 20.1733 10.9907 19.9105C10.9982 19.6476 10.9018 19.3924 10.7225 19.2C10.3743 18.8252 9.95323 18.5254 9.48508 18.3191C9.01694 18.1127 8.51159 18.0041 8 18C5.79375 18 4 20.0187 4 22.5C4 24.9812 5.79375 27 8 27C8.51159 26.9959 9.01694 26.8873 9.48508 26.6809C9.95323 26.4746 10.3743 26.1748 10.7225 25.8C10.9058 25.6084 11.0055 25.3518 10.9996 25.0867Z"
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 18C13.7938 18 12 20.0187 12 22.5C12 24.9812 13.7938 27 16 27C18.2063 27 20 24.9812 20 22.5C20 20.0187 18.2063 18 16 18ZM16 20C14.8969 20 14 21.1215 14 22.5C14 23.8785 14.8969 25 16 25C17.1031 25 18 23.8785 18 22.5C18 21.1215 17.1031 20 16 20Z"
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.2202 25.3697L24.5981 21.1782C24.6633 21.101 24.7098 21.0144 24.7366 20.9237C24.7633 20.8332 24.7702 20.7393 24.7573 20.6469C24.7444 20.5545 24.7116 20.464 24.6595 20.3806C24.6073 20.2971 24.5364 20.222 24.4494 20.161C24.3625 20.0999 24.2616 20.0545 24.1526 20.0287C24.0435 20.0029 23.9296 19.9976 23.8179 20.0131C23.7062 20.0287 23.6002 20.0647 23.5061 20.1179C23.4121 20.1711 23.3325 20.24 23.2708 20.3192L23.2659 20.3254C23.2174 20.3853 23.1795 20.4505 23.1529 20.5187C23.106 20.6466 23.0332 20.7628 22.9404 20.8613C22.8463 20.9611 22.7334 21.0408 22.6093 21.0972C22.4852 21.1535 22.3515 21.1857 22.2157 21.1929C22.08 21.2001 21.9437 21.1821 21.8144 21.1395C21.6851 21.0969 21.5644 21.0302 21.4598 20.9418C21.3552 20.8534 21.2686 20.7448 21.2067 20.6214C21.1448 20.4978 21.1093 20.3626 21.1035 20.2239C21.0977 20.0866 21.1211 19.9502 21.1716 19.823C21.3154 19.4469 21.5437 19.1065 21.8388 18.824C22.1347 18.5408 22.4909 18.3224 22.8821 18.1817C23.2733 18.0411 23.6915 17.9811 24.1085 18.0052C24.5256 18.0293 24.9332 18.137 25.3037 18.3222C25.6743 18.5073 25.9999 18.7661 26.257 19.0825C26.5141 19.3991 26.6962 19.7657 26.7889 20.1578C26.8815 20.55 26.882 20.9566 26.7901 21.349C26.6983 21.7411 26.517 22.1079 26.2607 22.4249L24.2038 24.977H25.805C26.0752 24.977 26.34 25.0766 26.5399 25.2625C26.7407 25.4494 26.86 25.7098 26.86 25.9885C26.86 26.2672 26.7407 26.5276 26.5399 26.7145C26.34 26.9004 26.0752 27 25.805 27H22.055C21.8652 27 21.6773 26.9509 21.5121 26.8559C21.3466 26.7608 21.2086 26.6221 21.1172 26.4521C21.0256 26.2817 20.9858 26.0884 21.0045 25.8948C21.0233 25.7013 21.0994 25.5196 21.2202 25.3697Z"
              fill={color}
            />
          </>
        ) : (
          <>
            <path
              d="M6 15C6.26522 15 6.51957 14.8946 6.70711 14.7071C6.89464 14.5196 7 14.2652 7 14V5H18V11C18 11.2652 18.1054 11.5196 18.2929 11.7071C18.4804 11.8946 18.7348 12 19 12H25V14C25 14.2652 25.1054 14.5196 25.2929 14.7071C25.4804 14.8946 25.7348 15 26 15C26.2652 15 26.5196 14.8946 26.7071 14.7071C26.8946 14.5196 27 14.2652 27 14V11C27.0001 10.8686 26.9743 10.7385 26.9241 10.6172C26.8739 10.4958 26.8003 10.3854 26.7075 10.2925L19.7075 3.2925C19.6146 3.19967 19.5042 3.12605 19.3829 3.07586C19.2615 3.02568 19.1314 2.9999 19 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V14C5 14.2652 5.10536 14.5196 5.29289 14.7071C5.48043 14.8946 5.73478 15 6 15ZM20 6.41375L23.5863 10H20V6.41375Z"
              fill={color}
            />
            <path
              d="M10.9996 25.0867C10.9938 24.8215 10.8828 24.5696 10.6912 24.3862C10.4996 24.2029 10.243 24.1033 9.9779 24.1091C9.71278 24.115 9.46084 24.2259 9.2775 24.4175C9.11518 24.5961 8.91822 24.7399 8.69861 24.84C8.47899 24.9402 8.24131 24.9946 8 25C6.8975 25 6 23.875 6 22.5C6 21.125 6.8975 20 8 20C8.24131 20.0054 8.47899 20.0598 8.69861 20.16C8.91822 20.2601 9.11518 20.4039 9.2775 20.5825C9.46174 20.7702 9.71248 20.8777 9.97543 20.8818C10.2384 20.8859 10.4924 20.7863 10.6824 20.6045C10.8724 20.4227 10.9831 20.1733 10.9907 19.9105C10.9982 19.6476 10.9018 19.3924 10.7225 19.2C10.3743 18.8252 9.95323 18.5254 9.48508 18.3191C9.01694 18.1127 8.51159 18.0041 8 18C5.79375 18 4 20.0187 4 22.5C4 24.9812 5.79375 27 8 27C8.51159 26.9959 9.01694 26.8873 9.48508 26.6809C9.95323 26.4746 10.3743 26.1748 10.7225 25.8C10.9058 25.6084 11.0055 25.3518 10.9996 25.0867Z"
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 18C13.7938 18 12 20.0187 12 22.5C12 24.9812 13.7938 27 16 27C18.2063 27 20 24.9812 20 22.5C20 20.0187 18.2063 18 16 18ZM16 20C14.8969 20 14 21.1215 14 22.5C14 23.8785 14.8969 25 16 25C17.1031 25 18 23.8785 18 22.5C18 21.1215 17.1031 20 16 20Z"
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.2202 25.3697L24.5981 21.1782C24.6633 21.101 24.7098 21.0144 24.7366 20.9237C24.7633 20.8332 24.7702 20.7393 24.7573 20.6469C24.7444 20.5545 24.7116 20.464 24.6595 20.3806C24.6073 20.2971 24.5364 20.222 24.4494 20.161C24.3625 20.0999 24.2616 20.0545 24.1526 20.0287C24.0435 20.0029 23.9296 19.9976 23.8179 20.0131C23.7062 20.0287 23.6002 20.0647 23.5061 20.1179C23.4121 20.1711 23.3325 20.24 23.2708 20.3192L23.2659 20.3254C23.2174 20.3853 23.1795 20.4505 23.1529 20.5187C23.106 20.6466 23.0332 20.7628 22.9404 20.8613C22.8463 20.9611 22.7334 21.0408 22.6093 21.0972C22.4852 21.1535 22.3515 21.1857 22.2157 21.1929C22.08 21.2001 21.9437 21.1821 21.8144 21.1395C21.6851 21.0969 21.5644 21.0302 21.4598 20.9418C21.3552 20.8534 21.2686 20.7448 21.2067 20.6214C21.1448 20.4978 21.1093 20.3626 21.1035 20.2239C21.0977 20.0866 21.1211 19.9502 21.1716 19.823C21.3154 19.4469 21.5437 19.1065 21.8388 18.824C22.1347 18.5408 22.4909 18.3224 22.8821 18.1817C23.2733 18.0411 23.6915 17.9811 24.1085 18.0052C24.5256 18.0293 24.9332 18.137 25.3037 18.3222C25.6743 18.5073 25.9999 18.7661 26.257 19.0825C26.5141 19.3991 26.6962 19.7657 26.7889 20.1578C26.8815 20.55 26.882 20.9566 26.7901 21.349C26.6983 21.7411 26.517 22.1079 26.2607 22.4249L24.2038 24.977H25.805C26.0752 24.977 26.34 25.0766 26.5399 25.2625C26.7407 25.4494 26.86 25.7098 26.86 25.9885C26.86 26.2672 26.7407 26.5276 26.5399 26.7145C26.34 26.9004 26.0752 27 25.805 27H22.055C21.8652 27 21.6773 26.9509 21.5121 26.8559C21.3466 26.7608 21.2086 26.6221 21.1172 26.4521C21.0256 26.2817 20.9858 26.0884 21.0045 25.8948C21.0233 25.7013 21.0994 25.5196 21.2202 25.3697Z"
              fill={color}
            />
            <path opacity="0.2" d="M19 5L22 8L25 11H19V5Z" fill={color} />
          </>
        )}
      </svg>
    );
  },
);
