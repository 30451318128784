import { type StoryblokAsset, getImageDimensions } from "./asset";
import { buildImageLoader } from "./image-loader";

type GetPosterUrlProps = {
  image: StoryblokAsset;
  width: number;
  aspectRatio?: number;
};

export function getPosterUrl({ image, width, aspectRatio }: GetPosterUrlProps) {
  if (!image.filename) return;

  const dimensions = getImageDimensions(image);
  if (!dimensions) return;

  const loader = buildImageLoader({
    aspectRatio: aspectRatio ?? dimensions.width / dimensions.height,
  });

  return loader({ width, src: image.filename });
}
