import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const PlantGrow = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A growing plant</title>
        {props.weight === "regular" ? (
          <>
            <g clipPath="url(#clip0_4610_130051)">
              <path
                d="M4 23H7"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 23H14"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 23H21"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25 23H28"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.035 12.965C14.9 7.73875 19.08 2.51374 27.9638 3.03624C28.4863 11.92 23.2613 16.1 18.035 12.965Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.25 13.25C14.53 9.44876 11.49 5.64876 5.02876 6.02876C4.64626 12.4875 8.44626 15.5275 12.25 13.25Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 13L14 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.2475 13.2476L15 16.0001"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 18H14"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 18L28 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 28H9"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23 28H28"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 28H19"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4610_130051">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        ) : (
          <>
            <g clipPath="url(#clip0_4610_200367)">
              <path
                opacity="0.2"
                d="M18.035 12.965C14.9 7.73875 19.08 2.51374 27.9638 3.03624C28.4863 11.92 23.2613 16.1 18.035 12.965Z"
                fill={color}
              />
              <path
                opacity="0.2"
                d="M12.25 13.25C14.53 9.44876 11.49 5.64876 5.02876 6.02876C4.64626 12.4875 8.44626 15.5275 12.25 13.25Z"
                fill={color}
              />
              <path
                d="M4 23H7"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 23H14"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 23H21"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25 23H28"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.035 12.965C14.9 7.73875 19.08 2.51374 27.9638 3.03624C28.4863 11.92 23.2613 16.1 18.035 12.965Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.25 13.25C14.53 9.44876 11.49 5.64876 5.02876 6.02876C4.64626 12.4875 8.44626 15.5275 12.25 13.25Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 13L14 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.2475 13.2476L15 16.0001"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 18H14"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 18L28 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 28H9"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23 28H28"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 28H19"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4610_200367">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    );
  },
);
