"use client";

import { storyblokEditable } from "@storyblok/react";

import { Icon } from "~components/ui";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "./rich-text";
import type { ListBlok } from "./types";

type ListProps = {
  blok: ListBlok;
  className?: string;
  variant?: "large" | "medium";
} & PageContext;

export function List({
  blok,
  className,
  variant = "large",
  ...context
}: ListProps) {
  if (!blok.items?.length) return null;

  const type = blok.items.some(item => item.icon) ? "unordered" : "ordered";

  if (type === "ordered") {
    return (
      <ol {...storyblokEditable(blok)} className={className}>
        {blok.items.map((item, idx) => (
          <li
            key={item._uid}
            className={cn(
              "flex items-center gap-sm border-border/200 border-b p-xs-sm",
              variant === "medium" && "py-2xs-xs",
            )}
            {...storyblokEditable(item)}
          >
            <span
              className={cn(
                "circle-icon t-strong-lg flex items-center justify-center",
                variant === "medium" &&
                  `t-strong-md [--circle-icon-p:theme("spacing.2xs")] [--circle-icon-w:28px]`,
              )}
            >
              {idx + 1}
            </span>
            <RichText data={item.text} {...context} />
          </li>
        ))}
      </ol>
    );
  }

  return (
    <ul className={className}>
      {blok.items.map(item => (
        <li
          key={item._uid}
          className={cn(
            "flex items-center gap-sm border-border/200 border-b p-xs-sm",
            variant === "medium" && "py-2xs-xs",
          )}
          {...storyblokEditable(item)}
        >
          <Icon
            name={item.icon || "Star"}
            weight="duotone"
            className={cn(
              "circle-icon",
              variant === "medium" &&
                `t-strong-md [--circle-icon-p:theme("spacing.2xs")] [--circle-icon-w:28px]`,
            )}
          />
          <RichText data={item.text} {...context} />
        </li>
      ))}
    </ul>
  );
}
