import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const Tractor = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>Tractor</title>
        {props.weight === "regular" ? (
          <>
            <path
              d="M18 19.5H9"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M4.5 17.5L5 14.5C6.5 12.5 10.2558 11.5 13.5 11.5L15.5 5H22.5L25.5 10"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M13.5 11.5V14.5H16L19 10H29L31.2093 13.2093"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19 10V5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <circle
              cx="24.5"
              cy="19.5"
              r="5.5"
              stroke={color}
              strokeWidth="2"
            />
            <circle cx="5.5" cy="21.5" r="3.5" stroke={color} strokeWidth="2" />
          </>
        ) : (
          <>
            <path
              opacity="0.2"
              d="M15.5 5L13.5 11.5V14.5H16L19 10H25.5L22.5 5H15.5Z"
              fill={color}
            />
            <circle opacity="0.2" cx="24.5" cy="19.5" r="6.5" fill={color} />
            <circle opacity="0.2" cx="5.5" cy="21.5" r="4.5" fill={color} />
            <path
              d="M18 19.5H9"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M4.5 17.5L5 14.5C6.5 12.5 10.2558 11.5 13.5 11.5L15.5 5H22.5L25.5 10"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M13.5 11.5V14.5H16L19 10H29L31.2093 13.2093"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19 10V5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <circle
              cx="24.5"
              cy="19.5"
              r="5.5"
              stroke={color}
              strokeWidth="2"
            />
            <circle cx="5.5" cy="21.5" r="3.5" stroke={color} strokeWidth="2" />
          </>
        )}
      </svg>
    );
  },
);
