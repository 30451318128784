import plugin from "tailwindcss/plugin";

export default plugin(({ theme, addComponents }) => {
  addComponents({
    ".circle-icon": {
      "--circle-icon-w": "32px",
      "--circle-icon-p": `theme("spacing.xs")`,

      boxSizing: "content-box",
      width: "var(--circle-icon-w)",
      height: "var(--circle-icon-w)",
      flexShrink: "0",
      padding: "calc(var(--circle-icon-p) - 2px)",

      border: "1px solid currentColor",
      borderRadius: theme("borderRadius.full"),

      "@media (min-width: 640px)": {
        padding: "var(--circle-icon-p)",
      },
    },
  });
});
