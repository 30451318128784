import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const Farmer = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>Farmer</title>
        {props.weight === "regular" ? (
          <>
            <g clipPath="url(#clip0_4581_47315)">
              <path
                d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 27C6.42125 22.8162 10.8187 20 16 20C21.1813 20 25.5787 22.8162 28 27"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 9C28 9.07904 27.9452 9.34452 27.3901 9.74832C26.8569 10.1362 26.0211 10.5272 24.8983 10.8726C22.6649 11.5598 19.5187 12 16 12C12.4813 12 9.33509 11.5598 7.1017 10.8726C5.97889 10.5272 5.14306 10.1362 4.60988 9.74832C4.0548 9.34452 4 9.07904 4 9C4 8.92096 4.0548 8.65548 4.60988 8.25168C5.14306 7.86381 5.97889 7.47283 7.1017 7.12735C9.33509 6.44016 12.4813 6 16 6C19.5187 6 22.6649 6.44016 24.8983 7.12735C26.0211 7.47283 26.8569 7.86381 27.3901 8.25168C27.9452 8.65548 28 8.92096 28 9Z"
                fill="white"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M10 8C10 4.68629 12.6863 2 16 2C19.3137 2 22 4.68629 22 8V8.63662C21.7216 8.67286 21.3772 8.71397 20.9735 8.75517C19.7766 8.87729 18.0609 9 16 9C13.9391 9 12.2234 8.87729 11.0265 8.75517C10.6228 8.71397 10.2784 8.67286 10 8.63662V8Z"
                fill="white"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M24 23L24 27"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M8 23L8 27"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M12 21V24C12 24.5523 12.4477 25 13 25H19C19.5523 25 20 24.5523 20 24V21"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4581_47315">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        ) : (
          <>
            <g clipPath="url(#clip0_4602_70457)">
              <path
                opacity="0.2"
                d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
                fill={color}
              />
              <path
                d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 27C6.42125 22.8162 10.8187 20 16 20C21.1813 20 25.5787 22.8162 28 27"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 9C28 9.07904 27.9452 9.34452 27.3901 9.74832C26.8569 10.1362 26.0211 10.5272 24.8983 10.8726C22.6649 11.5598 19.5187 12 16 12C12.4813 12 9.33509 11.5598 7.1017 10.8726C5.97889 10.5272 5.14306 10.1362 4.60988 9.74832C4.0548 9.34452 4 9.07904 4 9C4 8.92096 4.0548 8.65548 4.60988 8.25168C5.14306 7.86381 5.97889 7.47283 7.1017 7.12735C9.33509 6.44016 12.4813 6 16 6C19.5187 6 22.6649 6.44016 24.8983 7.12735C26.0211 7.47283 26.8569 7.86381 27.3901 8.25168C27.9452 8.65548 28 8.92096 28 9Z"
                fill="white"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M10 8C10 4.68629 12.6863 2 16 2C19.3137 2 22 4.68629 22 8V8.63662C21.7216 8.67286 21.3772 8.71397 20.9735 8.75517C19.7766 8.87729 18.0609 9 16 9C13.9391 9 12.2234 8.87729 11.0265 8.75517C10.6228 8.71397 10.2784 8.67286 10 8.63662V8Z"
                fill="white"
                stroke={color}
                strokeWidth="2"
              />
              <path
                d="M24 23L24 27"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M8 23L8 27"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M12 21V24C12 24.5523 12.4477 25 13 25H19C19.5523 25 20 24.5523 20 24V21"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4602_70457">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    );
  },
);
