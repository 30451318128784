import {
  type Locale,
  type MarketId,
  marketIds,
  marketsLookup,
} from "~app/config";

export type LocaleContext = {
  marketId: MarketId;
  locale: Locale;
  isEditorOnly?: boolean;
};

const DEFAULT_LOCALE_CONTEXT = {
  marketId: "global",
  locale: "en",
  isEditorOnly: false,
} as const;

/**
 * Finds a matching market and locale from the url prefix,
 * or returns the default values if unable to find a match.
 */
export function getLocaleContext(path: string | undefined): LocaleContext {
  const urlPrefix = path?.split("/")[0] ?? "";

  // No prefix means default market, default locale
  if (!urlPrefix) return DEFAULT_LOCALE_CONTEXT;

  const localeVerifier = /[a-z]{2}-[a-z]{2}/;

  // Check that the prefix matches our locale structure.
  if (!localeVerifier.test(urlPrefix)) return DEFAULT_LOCALE_CONTEXT;

  const [potentialLang, potentialMarketId] = urlPrefix.split("-");

  // Check that the marketId matches our markets.
  if (!marketIds.some(marketId => potentialMarketId === marketId)) {
    return DEFAULT_LOCALE_CONTEXT;
  }

  /**
   * Special case for market specific, editor-only english language.
   * example: en-dk, en-pl
   */
  if (potentialLang === "en") {
    return {
      marketId: potentialMarketId as MarketId,
      locale: "en",
      isEditorOnly: true,
    };
  }

  const market = marketsLookup[potentialMarketId as MarketId];

  const potentialLocale = `${potentialLang}-${potentialMarketId.toUpperCase()}`;

  // Check that the locale is valid against the supported market locales.
  if (!market.locales.some(locale => locale === potentialLocale)) {
    return DEFAULT_LOCALE_CONTEXT;
  }

  // We have a match!
  return {
    marketId: potentialMarketId as MarketId,
    locale: potentialLocale as Locale,
    isEditorOnly: false,
  };
}
