import { useEffect, useRef, useState } from "react";
import { cn, useScreenSize } from "~utils";
import { usePictureInPicture } from "~utils/video";
import { Icon } from "../icon";

export type VideoPlayerProps = {
  src: string;
  poster?: string;
  captions?: string;
  locale?: string;
  isAutoplay?: boolean;
  isLoop?: boolean;
  className?: string;
};

export function VideoPlayer({
  src,
  poster,
  captions,
  className,
  isAutoplay = false,
  isLoop = false,
  ...context
}: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const trackRef = useRef<HTMLTrackElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { isMobile } = useScreenSize();

  // Create captions file, and set it on video
  useEffect(() => {
    if (!captions || !trackRef.current) return;

    const blob = new Blob([captions], { type: "text/vtt" });
    const url = URL.createObjectURL(blob);
    trackRef.current.src = url;
  }, [captions]);

  // Enables picture in picture when the user scrolls away from a playing section.
  usePictureInPicture({ videoRef, isAutoplay });

  return (
    <div
      className={cn("relative w-full overflow-hidden rounded-md", className)}
    >
      <video
        ref={videoRef}
        controls
        poster={poster}
        autoPlay={isAutoplay}
        muted={isAutoplay}
        loop={isLoop}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        className="h-full w-full rounded-md"
      >
        <source src={src} />

        {captions && (
          <track
            ref={trackRef}
            kind="captions"
            srcLang={context.locale}
            default
          />
        )}
      </video>

      {/* We only show the play icon on desktop, as mobile has its own UI handling this. */}
      {!isMobile && !isPlaying && (
        <button
          onClick={() => setIsPlaying(true)}
          type="button"
          className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 text-others-white"
        >
          <Icon
            name="Play"
            weight="duotone"
            className="circle-icon"
            onClick={() => videoRef.current?.play()}
            aria-hidden
          />
        </button>
      )}
    </div>
  );
}
