import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const Satellite = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>Satellite</title>
        {props.weight === "regular" ? (
          <>
            <g clipPath="url(#clip0_4449_211064)">
              <mask id="path-1-inside-1_4449_211064" fill="white">
                <rect
                  x="14.0428"
                  y="5.73962"
                  width="8"
                  height="8"
                  rx="1"
                  transform="rotate(-45 14.0428 5.73962)"
                />
              </mask>
              <rect
                x="14.0428"
                y="5.73962"
                width="8"
                height="8"
                rx="1"
                transform="rotate(-45 14.0428 5.73962)"
                stroke={color}
                strokeWidth="4"
                strokeLinejoin="round"
                mask="url(#path-1-inside-1_4449_211064)"
              />
              <mask id="path-2-inside-2_4449_211064" fill="white">
                <rect
                  x="-0.0993652"
                  y="19.8818"
                  width="8"
                  height="8"
                  rx="1"
                  transform="rotate(-45 -0.0993652 19.8818)"
                />
              </mask>
              <rect
                x="-0.0993652"
                y="19.8818"
                width="8"
                height="8"
                rx="1"
                transform="rotate(-45 -0.0993652 19.8818)"
                stroke={color}
                strokeWidth="4"
                strokeLinejoin="round"
                mask="url(#path-2-inside-2_4449_211064)"
              />
              <mask id="path-3-inside-3_4449_211064" fill="white">
                <rect
                  x="5.31161"
                  y="9.67902"
                  width="5.91882"
                  height="11.6153"
                  rx="1"
                  transform="rotate(-45 5.31161 9.67902)"
                />
              </mask>
              <rect
                x="5.31161"
                y="9.67902"
                width="5.91882"
                height="11.6153"
                rx="1"
                transform="rotate(-45 5.31161 9.67902)"
                stroke={color}
                strokeWidth="4"
                strokeLinejoin="round"
                mask="url(#path-3-inside-3_4449_211064)"
              />
              <mask id="path-4-inside-4_4449_211064" fill="white">
                <path d="M24.1999 14.4825C23.5499 13.8325 22.7782 13.3169 21.9289 12.9651C21.0796 12.6133 20.1694 12.4323 19.2501 12.4323C18.3309 12.4323 17.4206 12.6133 16.5714 12.9651C15.7221 13.3169 14.9504 13.8325 14.3004 14.4825C13.6504 15.1325 13.1348 15.9042 12.783 16.7535C12.4312 17.6027 12.2501 18.513 12.2501 19.4323C12.2501 20.3515 12.4312 21.2618 12.783 22.111C13.1348 22.9603 13.6504 23.732 14.3004 24.382L19.2501 19.4323L24.1999 14.4825Z" />
              </mask>
              <path
                d="M24.1999 14.4825C23.5499 13.8325 22.7782 13.3169 21.9289 12.9651C21.0796 12.6133 20.1694 12.4323 19.2501 12.4323C18.3309 12.4323 17.4206 12.6133 16.5714 12.9651C15.7221 13.3169 14.9504 13.8325 14.3004 14.4825C13.6504 15.1325 13.1348 15.9042 12.783 16.7535C12.4312 17.6027 12.2501 18.513 12.2501 19.4323C12.2501 20.3515 12.4312 21.2618 12.783 22.111C13.1348 22.9603 13.6504 23.732 14.3004 24.382L19.2501 19.4323L24.1999 14.4825Z"
                stroke={color}
                strokeWidth="4"
                mask="url(#path-4-inside-4_4449_211064)"
              />
              <path
                d="M19.2163 20.2437L17 20.1821L20.1822 17L20.2437 19.2163C20.2596 19.7902 19.7902 20.2596 19.2163 20.2437Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M11.2144 14.2249L8.38593 17.0533"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M16.8712 8.56799L14.0428 11.3964"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M25.7279 17.7279C25.7279 19.8496 24.8851 21.8845 23.3848 23.3848C21.8845 24.8851 19.8497 25.7279 17.7279 25.7279"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.543 18.7252C29.543 21.6426 28.3841 24.4405 26.3212 26.5034C24.2583 28.5663 21.4604 29.7252 18.543 29.7252"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4449_211064">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        ) : (
          <>
            <g clipPath="url(#clip0_4602_70436)">
              <path
                opacity="0.2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7499 5.03254C14.3594 5.42307 14.3594 6.05623 14.7499 6.44676L18.9925 10.6894C19.3831 11.0799 20.0162 11.0799 20.4067 10.6894L24.6494 6.44676C25.0399 6.05623 25.0399 5.42307 24.6494 5.03254L20.4067 0.789902C20.0162 0.399378 19.3831 0.399378 18.9925 0.789902L14.7499 5.03254ZM0.607743 19.1747C0.217219 19.5652 0.217219 20.1984 0.607743 20.5889L4.85038 24.8315C5.24091 25.2221 5.87407 25.2221 6.2646 24.8315L10.5072 20.5889C10.8978 20.1984 10.8978 19.5652 10.5072 19.1747L6.2646 14.9321C5.87407 14.5415 5.24091 14.5415 4.85038 14.9321L0.607743 19.1747ZM6.01871 10.3861C5.62818 9.99563 5.62818 9.36246 6.01871 8.97194L8.78973 6.20091C9.18026 5.81039 9.81342 5.81039 10.2039 6.20091L16.8571 12.854C17.6233 12.5753 18.4332 12.4323 19.2501 12.4323C20.1694 12.4323 21.0796 12.6133 21.9289 12.9651C22.7782 13.3169 23.5499 13.8325 24.1999 14.4825L19.2501 19.4323L14.3004 24.382C13.6504 23.732 13.1348 22.9603 12.783 22.1111C12.4312 21.2618 12.2501 20.3515 12.2501 19.4323C12.2501 18.6154 12.3931 17.8055 12.6719 17.0393L6.01871 10.3861Z"
                fill={color}
              />
              <mask id="path-2-inside-1_4602_70436" fill="white">
                <rect
                  x="14.0428"
                  y="5.73962"
                  width="8"
                  height="8"
                  rx="1"
                  transform="rotate(-45 14.0428 5.73962)"
                />
              </mask>
              <rect
                x="14.0428"
                y="5.73962"
                width="8"
                height="8"
                rx="1"
                transform="rotate(-45 14.0428 5.73962)"
                stroke={color}
                strokeWidth="4"
                strokeLinejoin="round"
                mask="url(#path-2-inside-1_4602_70436)"
              />
              <mask id="path-3-inside-2_4602_70436" fill="white">
                <rect
                  x="-0.0993652"
                  y="19.8818"
                  width="8"
                  height="8"
                  rx="1"
                  transform="rotate(-45 -0.0993652 19.8818)"
                />
              </mask>
              <rect
                x="-0.0993652"
                y="19.8818"
                width="8"
                height="8"
                rx="1"
                transform="rotate(-45 -0.0993652 19.8818)"
                stroke={color}
                strokeWidth="4"
                strokeLinejoin="round"
                mask="url(#path-3-inside-2_4602_70436)"
              />
              <mask id="path-4-inside-3_4602_70436" fill="white">
                <rect
                  x="5.31161"
                  y="9.67902"
                  width="5.91882"
                  height="11.6153"
                  rx="1"
                  transform="rotate(-45 5.31161 9.67902)"
                />
              </mask>
              <rect
                x="5.31161"
                y="9.67902"
                width="5.91882"
                height="11.6153"
                rx="1"
                transform="rotate(-45 5.31161 9.67902)"
                stroke={color}
                strokeWidth="4"
                strokeLinejoin="round"
                mask="url(#path-4-inside-3_4602_70436)"
              />
              <mask id="path-5-inside-4_4602_70436" fill="white">
                <path d="M24.1999 14.4825C23.5499 13.8325 22.7782 13.3169 21.9289 12.9651C21.0796 12.6133 20.1694 12.4323 19.2501 12.4323C18.3309 12.4323 17.4206 12.6133 16.5714 12.9651C15.7221 13.3169 14.9504 13.8325 14.3004 14.4825C13.6504 15.1325 13.1348 15.9042 12.783 16.7535C12.4312 17.6027 12.2501 18.513 12.2501 19.4323C12.2501 20.3515 12.4312 21.2618 12.783 22.111C13.1348 22.9603 13.6504 23.732 14.3004 24.382L19.2501 19.4323L24.1999 14.4825Z" />
              </mask>
              <path
                d="M24.1999 14.4825C23.5499 13.8325 22.7782 13.3169 21.9289 12.9651C21.0796 12.6133 20.1694 12.4323 19.2501 12.4323C18.3309 12.4323 17.4206 12.6133 16.5714 12.9651C15.7221 13.3169 14.9504 13.8325 14.3004 14.4825C13.6504 15.1325 13.1348 15.9042 12.783 16.7535C12.4312 17.6027 12.2501 18.513 12.2501 19.4323C12.2501 20.3515 12.4312 21.2618 12.783 22.111C13.1348 22.9603 13.6504 23.732 14.3004 24.382L19.2501 19.4323L24.1999 14.4825Z"
                stroke={color}
                strokeWidth="4"
                mask="url(#path-5-inside-4_4602_70436)"
              />
              <path
                d="M19.2163 20.2437L17 20.1821L20.1822 17L20.2437 19.2163C20.2596 19.7902 19.7902 20.2596 19.2163 20.2437Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M11.2144 14.2249L8.38593 17.0533"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M16.8712 8.56799L14.0428 11.3964"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M25.7279 17.7279C25.7279 19.8496 24.8851 21.8845 23.3848 23.3848C21.8845 24.8851 19.8497 25.7279 17.7279 25.7279"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.543 18.7252C29.543 21.6426 28.3841 24.4405 26.3212 26.5034C24.2583 28.5663 21.4604 29.7252 18.543 29.7252"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4602_70436">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    );
  },
);
