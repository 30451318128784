import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ForwardedRef,
  forwardRef,
} from "react";

import { cn } from "~utils";

import { Icon } from "../icon";
import styles from "./button.module.css";

type ButtonProps = {
  children?: React.ReactNode;
  size?: "regular" | "small" | "mini";
} & (
  | {
      variant?: "primary" | "secondary";
      editorial?: true;
      arrow?: never;
    }
  | {
      variant?: "primary" | "secondary" | "ternary";
      editorial?: never;
      arrow?: boolean;
    }
) &
  (
    | AnchorHTMLAttributes<HTMLAnchorElement>
    | ButtonHTMLAttributes<HTMLButtonElement>
  );

function ButtonBase(
  {
    arrow,
    children,
    editorial,
    variant = "primary",
    size,
    className,
    ...rest
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
) {
  const extendedClassNames = cn(
    styles.button,
    styles[variant],
    size && styles[size],
    editorial && styles.editorial,
    className,
  );

  const Inner = () => (
    <div className={styles.inner}>
      {editorial && <Icon name="ArrowRight" className={styles["pre-arrow"]} />}

      <span className={styles.text}>{children}</span>

      {(arrow || editorial) && (
        <Icon name="ArrowRight" className={styles["post-arrow"]} />
      )}
    </div>
  );

  if (isAnchorProps(rest)) {
    return (
      <a
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...rest}
        className={extendedClassNames}
      >
        <Inner />
      </a>
    );
  }

  if (isButtonProps(rest)) {
    return (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        {...rest}
        type={rest.type ?? "button"}
        className={extendedClassNames}
      >
        <Inner />
      </button>
    );
  }

  return null;
}

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(ButtonBase);

function isAnchorProps(
  props: unknown,
): props is AnchorHTMLAttributes<HTMLAnchorElement> {
  if (!props || typeof props !== "object") return false;

  return "href" in props;
}

function isButtonProps(
  props: unknown,
): props is ButtonHTMLAttributes<HTMLButtonElement> {
  if (!props || typeof props !== "object") return false;

  return !("href" in props);
}
