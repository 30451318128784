import plugin from "tailwindcss/plugin";

export default plugin(({ addUtilities }) => {
  addUtilities({
    ".h-screen-safe": {
      height: ["100vh", "100dvh"],
    },
    ".min-h-screen-safe": {
      "min-height": ["100vh", "100dvh"],
    },
  });
});
