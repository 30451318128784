import { type ISbRichtext, renderRichText } from "@storyblok/react";
import { ReactNode } from "react";
import readingTime from "reading-time";

/**
 * When storyblok renders rich text, it wraps it in a <p> tag
 * That is not ideal when you want to style the <p> tag yourself.
 */
export function unwrapParagraph<TType extends string | undefined>(
  renderedHtml: TType,
) {
  if (!renderedHtml) return renderedHtml;

  /**
   * Checks if the text is wrapped in a p tag, including any attributes on that p tag,
   * and including any whitespace and line breaks around that p tag.
   * If it is, removes that p tag and whitespace, leaving only the inner content.
   */
  return renderedHtml.replace(/^[\n\r\s]*<p[^>]*>(.*)<\/p>[\n\r\s]*$/, "$1");
}

export function getReadingTime(richText: ISbRichtext | undefined) {
  if (!richText) return null;

  const textValue = renderRichText(richText);

  const cleanedValue = textValue?.replace(/<[^>]+>/g, "") ?? "";
  return readingTime(cleanedValue);
}
