import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const TillageSlash = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>Tillage crossed out</title>
        {props.weight === "regular" ? (
          <>
            <path
              d="M24.9244 20.2843C25.7718 18.5192 26.0767 16.5425 25.8006 14.6041C25.5245 12.6657 24.6798 10.8528 23.3734 9.39445C22.0669 7.93613 20.3574 6.89798 18.4609 6.41126C16.5644 5.92454 14.5662 6.01112 12.7189 6.66005"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.27961 8.73112C7.36663 10.4998 6.22797 12.9512 6.11056 15.5538C5.99314 18.1565 6.90644 20.7005 8.65239 22.6342C10.3983 24.5679 12.8362 25.7354 15.4373 25.8835C18.0383 26.0316 20.5929 25.1483 22.5471 23.4253"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.0799 13.9232C13.5333 14.4285 13.208 15.1289 13.1744 15.8725C13.1409 16.6161 13.4018 17.343 13.9007 17.8955C14.3995 18.448 15.0961 18.7815 15.8392 18.8239C16.5824 18.8662 17.3123 18.6138 17.8706 18.1215"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 6L18 3"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M16 29L18 26"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26 15L29 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M3 15L6 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M24.4853 25.8995L24.3361 25.1534"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M8.22183 9.63605L7.69462 7.00001"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9.63606 23.7782L6.10053 24.4853"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.8995 7.51473L22.364 8.22183"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M6 5L26.5 27.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        ) : (
          <>
            <path
              d="M24.9244 20.2843C25.7718 18.5192 26.0767 16.5425 25.8006 14.6041C25.5245 12.6657 24.6798 10.8528 23.3734 9.39445C22.0669 7.93613 20.3574 6.89798 18.4609 6.41126C16.5644 5.92454 14.5662 6.01112 12.7189 6.66005"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.27961 8.73112C7.36663 10.4998 6.22797 12.9512 6.11056 15.5538C5.99314 18.1565 6.90644 20.7005 8.65239 22.6342C10.3983 24.5679 12.8362 25.7354 15.4373 25.8835C18.0383 26.0316 20.5929 25.1483 22.5471 23.4253"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.0799 13.9232C13.5333 14.4285 13.208 15.1289 13.1744 15.8725C13.1409 16.6161 13.4018 17.343 13.9007 17.8955C14.3995 18.448 15.0961 18.7815 15.8392 18.8239C16.5824 18.8662 17.3123 18.6138 17.8706 18.1215"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 6L18 3"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M16 29L18 26"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26 15L29 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M3 15L6 17"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M24.4853 25.8995L24.3361 25.1534"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M8.22183 9.63605L7.69462 7.00001"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M9.63606 23.7782L6.10053 24.4853"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M25.8995 7.51473L22.364 8.22183"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M6 5L26.5 27.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        )}
      </svg>
    );
  },
);
