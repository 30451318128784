/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/agreena/Dotcom/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/agreena/Dotcom/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'dotcom';

export const ApiKey: Record<Environment, string> = {
  dotcom: '51b382b88656baa37e680ee0dd9dc42a'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: 'd9ddb688-4cbf-4a10-be2d-492434bc2e94'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface NavigationLinkClickedProperties {
  navigationArea: string;
  navigationLinkHref: string;
}

export interface SectionLinkClickedProperties {
  /**
   * The type of page in the cms and frontend, for example `DynamicPage`
   */
  pageType: string;
  sectionLinkHref: string;
  /**
   * The type of section in the cms and frontend code, for example `HeroSection`
   */
  sectionType: string;
}

export interface TypeformQuestionChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * The id of the Typeform that the user is interacting with.
   *
   * *Use this for grouping typeform events, so you can compare performance of each form.*
   */
  typeformId: string;
  /**
   * The Typeform question reference id.
   *  *Use this to identify which questions are performing well, and which sees user dropoff*
   */
  typeformQuestionRef: string;
}

export interface TypeformReadyProperties {
  /**
   * The id of the Typeform that the user is interacting with.
   *
   * *Use this for grouping typeform events, so you can compare performance of each form.*
   */
  typeformId: string;
}

export interface TypeformSubmittedProperties {
  typeformId: string;
}

export class NavigationLinkClicked implements BaseEvent {
  event_type = 'Navigation Link Clicked';

  constructor(
    public event_properties: NavigationLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SectionLinkClicked implements BaseEvent {
  event_type = 'Section Link Clicked';

  constructor(
    public event_properties: SectionLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TypeformQuestionChanged implements BaseEvent {
  event_type = 'Typeform Question Changed';

  constructor(
    public event_properties: TypeformQuestionChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TypeformReady implements BaseEvent {
  event_type = 'Typeform Ready';

  constructor(
    public event_properties: TypeformReadyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TypeformSubmitted implements BaseEvent {
  event_type = 'Typeform Submitted';

  constructor(
    public event_properties: TypeformSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Navigation Link Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/Dotcom/events/main/latest/Navigation%20Link%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. navigationArea)
   * @param options Amplitude event options.
   */
  navigationLinkClicked(
    properties: NavigationLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavigationLinkClicked(properties), options);
  }

  /**
   * Section Link Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/Dotcom/events/main/latest/Section%20Link%20Clicked)
   *
   * Event that captures when a user clicks on a link within a section of the website.
   *
   * *Use sectionType to target specific sections*
   *
   * @param properties The event's properties (e.g. pageType)
   * @param options Amplitude event options.
   */
  sectionLinkClicked(
    properties: SectionLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SectionLinkClicked(properties), options);
  }

  /**
   * Typeform Question Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/Dotcom/events/main/latest/Typeform%20Question%20Changed)
   *
   * Event fired when the user goes to the next question in a Typeform
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  typeformQuestionChanged(
    properties: TypeformQuestionChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TypeformQuestionChanged(properties), options);
  }

  /**
   * Typeform Ready
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/Dotcom/events/main/latest/Typeform%20Ready)
   *
   * Event fired when the Typeform on a page is loaded and ready
   *
   * @param properties The event's properties (e.g. typeformId)
   * @param options Amplitude event options.
   */
  typeformReady(
    properties: TypeformReadyProperties,
    options?: EventOptions,
  ) {
    return this.track(new TypeformReady(properties), options);
  }

  /**
   * Typeform Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/Dotcom/events/main/latest/Typeform%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. typeformId)
   * @param options Amplitude event options.
   */
  typeformSubmitted(
    properties: TypeformSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TypeformSubmitted(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
