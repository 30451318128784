import type { IconProps } from "@phosphor-icons/react/dist/ssr";
import { forwardRef } from "react";

export const FieldGrid = forwardRef<SVGSVGElement, IconProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>A field with a grid of lines on it</title>
        {props.weight === "regular" ? (
          <>
            <path
              d="M3 11L29 11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M3 16L29 16"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M2 22H30"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 11L20.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 11L11.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 11L28.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 11L3.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 8.5L7 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 8.5L4 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 8.5L14 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 8.5L11 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 8.5L21 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 8.5L18 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 8.5L28 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 8.5L25 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        ) : (
          <>
            <path
              d="M3 11L29 11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              opacity="0.2"
              d="M29 11L3 11L2 22L30 22L29 11Z"
              fill={color}
            />
            <path
              d="M3 16L29 16"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M2 22H30"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 11L20.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 11L11.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 11L28.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 11L3.5 24"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 8.5L7 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M5.5 8.5L4 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 8.5L14 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.5 8.5L11 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 8.5L21 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 8.5L18 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 9V11"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 8.5L28 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M26.5 8.5L25 7"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </>
        )}
      </svg>
    );
  },
);
