import {
  type ISbStoriesParams,
  type ISbStory,
  type ISbStoryData,
  type ISbStoryParams,
  getStoryblokApi,
} from "@storyblok/react";
import type { Metadata } from "next";

import {
  type Locale,
  type MarketId,
  locales,
  marketIds,
  marketsLookup,
} from "~app/config";

import { cache } from "react";
import type { NewsPageItemType } from "~components/cms/news-page";
import type { ArticleType } from "~components/types";
import { globalRelations, subPageRelations } from "./relations";
import type { TranslatedSlug } from "./translation";

export function isStoryblokClientLoaded() {
  const storyblokClient = getStoryblokApi();

  return !!storyblokClient;
}

/**
 * The data we can read from the search parameters of the editor.
 * Useful to determine IF the page is running from inside the editor, and what story the editor is on.
 */
export function getEditorContext(
  searchParams: Record<string, string> | undefined,
) {
  // Storyblok editor specific query param that points to the id of the current story.
  const storyId = searchParams?._storyblok;

  let params: Record<string, string> | undefined = undefined;

  if (searchParams) {
    params = {};

    for (const [key, value] of Object.entries(searchParams ?? {})) {
      if (!key.startsWith("_storyblok")) continue;

      params[key] = value;
    }
  }

  const isInEditor = Boolean(storyId);

  // Ensure that we only get content preview when inside the editor or preview mode.
  const version: ISbStoriesParams["version"] = isInEditor
    ? "draft"
    : "published";

  return {
    storyId,
    version,
    params,
    isInEditor,
  };
}

/**
 * Fetches the storyblok story, looking in market specific folder first,
 * then falling back to global.
 */
export async function getStory(
  path: string,
  locale: Locale,
  marketId: MarketId,
  version: ISbStoriesParams["version"],
  resolveRelations = globalRelations,
): Promise<ISbStory> {
  const storyblokClient = getStoryblokApi();

  const params = {
    language: locale.toLowerCase(),
    version,
    // Make sure that we always bust the cache every call when in editor,
    // being more conservative than storyblok SDK to avoid out of sync issues.
    cv: version === "draft" ? Date.now() : undefined,
    resolve_relations: resolveRelations,
    resolve_links: "url",
  } as ISbStoryParams;

  try {
    // See if we can get a market specific version
    return await storyblokClient.get(`cdn/stories/${marketId}/${path}`, params);
  } catch (err) {
    // No market specific version found
  }

  // Otherwise fall back to global
  return await storyblokClient.get(`cdn/stories/global/${path}`, params);
}

// Memoize results for cases when the same API call is made more than once on SSR
export const cachedGetStory = cache(getStory);

export type GetSubPagesOptions = {
  locale: Locale;
  marketId: MarketId;
  version: ISbStoriesParams["version"];
  resolveRelations?: string[];
  contentTypeFilter?: NewsPageItemType["component"]; // TODO: Make it global, this is News Page only logic
  fieldTypeFilter?: ArticleType; // TODO: Make it global, this is News Page only logic
};

export type SubPage<TStory = unknown> = {
  story: ISbStoryData<TStory>;
  url: string;
};

/**
 * Fetches all storyblok pages at a path, looking in market specific folders first,
 * then falling back to global.
 */
export async function getSubPages<
  TStory extends { component: string } = { component: "" },
>(
  path: string,
  {
    locale,
    marketId,
    resolveRelations = subPageRelations,
    version,
    contentTypeFilter,
    fieldTypeFilter,
  }: GetSubPagesOptions,
): Promise<SubPage<TStory>[]> {
  const storyblokClient = getStoryblokApi();

  const context = { locale, marketId };

  const params = {
    is_startpage: false,
    language: locale.toLowerCase(),
    version,
    // Make sure that we always bust the cache every call when in editor,
    // being more conservative than storyblok SDK to avoid out of sync issues.
    cv: version === "draft" ? Date.now() : undefined,
    resolve_relations: resolveRelations,
    resolve_assets: 1,
    sort_by: "content.date:desc",
    filter_query: {
      isDisabled: {
        is: false,
      },
      ...(fieldTypeFilter
        ? {
            type: {
              like: fieldTypeFilter,
            },
          }
        : {}),
    },
    ...(contentTypeFilter
      ? {
          content_type: contentTypeFilter,
        }
      : {}),
  } as ISbStoriesParams;

  const subPages: SubPage<TStory>[] = [];

  const stories: ISbStoryData<TStory>[] = await storyblokClient.getAll(
    "cdn/stories",
    {
      ...params,
      starts_with: `${marketId}/${path}`,
    },
  );

  for (const story of stories) {
    // Simplest way to check that it is a page, without maintaining an include/exclude list.
    if (!story.content.component?.endsWith("Page")) continue;

    subPages.push({
      story,
      url: buildStoryUrl(story.full_slug, context),
    });
  }

  return subPages;
}

/**
 * Builds a Next.js alternate hreflang lookup from storyblok alternates data.
 */
export async function getAlternateUrls(
  srcStory: ISbStoryData,
  context: {
    locale: Locale;
    version: ISbStoryParams["version"];
  },
) {
  const api = getStoryblokApi();
  const alternateUrls: NonNullable<Metadata["alternates"]>["languages"] = {};

  const alternateSrcSlugs = Object.values(srcStory.alternates)
    .filter(story => context.version === "draft" || story.published)
    .map(story => story.full_slug);

  // Get the full version of the stories, as otherwise we cannot get the translated slugs.
  const alternateStories: ISbStoryData<unknown>[] = alternateSrcSlugs.length
    ? await api.getAll("cdn/stories", {
        by_slugs: alternateSrcSlugs.join(","),
        excluding_fields: "body",
        resolve_links: "0",
        version: context.version,
      })
    : [];

  const targetStories = [
    srcStory,
    ...alternateStories,
    // Sort global last, as market specific pages are more important than globally translated pages.
  ].sort(item => (getSlugMarketId(item.full_slug) === "global" ? 1 : -1));

  for (const targetStory of targetStories) {
    const marketId = getSlugMarketId(targetStory.full_slug);
    const market = marketsLookup[marketId];

    // Loop over the market locales of the story (in case of `global` => all migrated locales)
    for (const locale of market.locales) {
      // If this locale is already set, skip it.
      if (alternateUrls[locale]) continue;

      const translatedSlug = targetStory.translated_slugs?.find(
        item => item.lang === locale.toLowerCase(),
      ) as TranslatedSlug | null;

      /**
       * We want to make sure that we don't create any extra urls for
       * empty translations.
       */
      const isNonDefaultLocale = locale !== market.locales[0];
      if (isNonDefaultLocale && translatedSlug?.published !== true) continue;

      const fullSlug = translatedSlug
        ? translatedSlug.path
        : targetStory.full_slug;

      /**
       * Builds the url for the hreflang tag, and assign it to the correct locale key.
       * x-default is a custom tag used for international pages that have no preferred locale.
       *
       * https://developers.google.com/search/blog/2013/04/x-default-hreflang-for-international-pages
       */
      const url = buildStoryUrl(fullSlug, {
        locale,
        marketId,
      });

      /**
       * Currently, `en` is used for both uk and as our global default.
       */
      if (locale === "en") {
        alternateUrls["en-GB"] = url;
        alternateUrls.en = url;
        alternateUrls["x-default"] = url;
      } else {
        alternateUrls[locale] = url;
      }
    }
  }

  return alternateUrls;
}

/**
 * Gets the slug market id, eg. the root folder part of the fullSlug from a storyblok story.
 */
function getSlugMarketId(fullSlug: string | undefined): MarketId {
  if (!fullSlug) return "global";

  const fullSlugWithoutLocale = removeLocalePrefix(fullSlug);
  const pathParts = fullSlugWithoutLocale.split("/");

  if (!pathParts.length) return "global";

  const potentialMarketId = pathParts[0].toLowerCase();

  if (!marketIds.some(marketId => marketId === potentialMarketId)) {
    return "global";
  }

  return potentialMarketId as MarketId;
}

/**
 * Removes any locale and market prefixes from the path.
 */
export function getCleanPath(fullPath: string) {
  const prefixes = [
    ...marketIds,
    ...locales,
    "en\\-[a-z]{2}", // special editor-only english locale.
  ];

  // Any prefix, followed by a slash or end of string, any number of times.
  const matcher = new RegExp(`^((${prefixes.join("|")})(/|$))+`, "i");

  return fullPath.replace(matcher, "");
}

/**
 * Removes the locale prefix from a fullSlug, if present.
 */
export function removeLocalePrefix(fullPath: string) {
  const localePrefixMatcher = new RegExp(
    `^((${locales.join("|")})(/|$))+`,
    "i",
  );
  const enSpecialPrefixMatcher = /^en\-[a-z]{2}(\/|$)/i;

  return fullPath
    .replace(localePrefixMatcher, "")
    .replace(enSpecialPrefixMatcher, "");
}

/**
 * Builds a full url with locale prefix
 * based on the storyblok full slug and the page context.
 */
export function buildStoryUrl(
  fullPath: string,
  context: {
    locale: Locale;
    marketId: MarketId;
  },
) {
  if (fullPath == null) return "";

  const host = process.env.DOTCOM_HOSTNAME || global.location?.host;
  const localeLower = context.locale.toLowerCase();

  let path = getCleanPath(fullPath);

  if (path !== "" && !path.endsWith("/")) {
    path = `${path}/`;
  }

  const isGlobalDefault = context.marketId === "global";

  if (isGlobalDefault) return `https://${host}/${path}`;

  return `https://${host}/${localeLower}/${path}`;
}
