import plugin from "tailwindcss/plugin";

export default plugin(({ addUtilities }) => {
  addUtilities({
    ".scrollbar-hidden": {
      "scrollbar-width": "none",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  });
});
