import * as Dialog from "@radix-ui/react-dialog";
import { cn } from "~utils";
import { Button } from "../button";
import { Icon } from "../icon";
import { Rails } from "../rails";

type ImageGalleryRootProps = {
  children: React.ReactNode;
  open: boolean;
  initialIndex?: number;
  onOpenChange: (value: boolean) => void;
};

export function ImageGalleryRoot({
  children,
  open,
  onOpenChange,
}: ImageGalleryRootProps) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="section-satellite fixed top-[0] left-[0] z-30 h-full w-full animate-fadeIn bg-surface/300 opacity-75" />

        <Dialog.Content
          className={cn(
            "[--image-gallery-w:min(66.6em,100vw)]",
            "section-satellite fixed top-[0] left-[0] z-40 grid h-full w-full grid-cols-1",
          )}
          onClick={() => onOpenChange(false)}
        >
          <Rails.Root className="grid place-content-center place-items-center gap-xs">
            <button
              onClick={() => onOpenChange(false)}
              type="button"
              className="p- t-strong-2xl top-3xl z-40 m-xs ml-auto box-content text-primaryUI-bg md:top-lg"
            >
              <Icon name="X" />
            </button>

            <Rails.Viewport
              className={cn(
                "grid auto-cols-[100%] grid-flow-col place-items-center overflow-x-auto",
                "scrollbar-hidden snap-x snap-mandatory scroll-mx-[10%]",
              )}
            >
              {children}
            </Rails.Viewport>

            <div className="flex justify-between gap-md rounded-b-lg bg-shades-white-500 p-sm">
              <Rails.Control
                action="prev"
                onClick={ev => ev.stopPropagation()}
                asChild
              >
                <Button>Previous</Button>
              </Rails.Control>

              <Rails.Control
                action="next"
                asChild
                onClick={ev => ev.stopPropagation()}
              >
                <Button>Next</Button>
              </Rails.Control>
            </div>
          </Rails.Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

type ImageGalleryItemProps = {
  children: React.ReactNode;
};

export function ImageGalleryItem({ children }: ImageGalleryItemProps) {
  return (
    <Rails.Item
      className={cn(
        "flex max-h-[80vh] w-[var(--image-gallery-w)] snap-center place-content-center transition-opacity",
      )}
      onClick={ev => ev.stopPropagation()}
    >
      {children}
    </Rails.Item>
  );
}
