type StoryblokImageLoaderProps = {
  src: string;
  width: number;
  height?: number;
  quality?: number;
  focus?: string | null;
};

export function imageLoader({
  src,
  width,
  height,
  quality,
  focus,
}: StoryblokImageLoaderProps) {
  const filters = new Map();

  filters.set("quality", quality || 75);

  if (focus) {
    filters.set("focal", focus);
  }

  const filterValues = Array.from(filters)
    .map(([key, val]) => `${key}(${val})`)
    .join(":");

  return `${src}/m/${width}x${height ?? 0}/filters:${filterValues}`;
}

type BuildImageLoaderOptions = {
  aspectRatio?: number;
  focus?: string | null;
};

export function buildImageLoader({
  aspectRatio,
  focus,
}: BuildImageLoaderOptions) {
  return (innerProps: StoryblokImageLoaderProps) =>
    imageLoader({
      ...innerProps,
      height: aspectRatio
        ? Math.round(innerProps.width / aspectRatio)
        : innerProps.height,
      focus,
    });
}
