"use client";

import { useEffect, useState } from "react";

export function useIsReducedMotion() {
  const [isReducedMotion, setIsReducedMotion] = useState(false);

  useEffect(() => {
    const matchResult = matchMedia("(prefers-reduced-motion: reduce)");

    setIsReducedMotion(
      // Backwards compatible with older browsers
      (typeof matchResult === "boolean" && matchResult === true) ||
        matchResult.matches,
    );
  }, []);

  return isReducedMotion;
}
